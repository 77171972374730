import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Button, FormControlLabel, IconButton, Input, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

import { CACHE_KEYS } from 'utils/constants';
import { usePatchRole } from 'hooks/roles';
import { useAllOrganizations, usePatchOrganization } from 'hooks/organizations';
import { RoleDetails } from '@types';
import { adminAPI } from 'api';

type Props = {
  isAllUsersView: boolean;
  selectedRole?: RoleDetails;
  setSelectedRoleId: (roleId: RoleDetails['roleId']) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(0.5),
    },
    submitBtn: {
      marginLeft: theme.spacing(2),
    },
    textField: {
      ...theme.typography.h4,
    },
    title: {
      fontWeight: 700,
      marginRight: theme.spacing(4),
    },
    titleRow: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(0.5),
    },
  }),
);

const AdminRoleDetails = ({ selectedRole, setSelectedRoleId }: Props) => {
  const classes = useStyles();
  const [isEditMode, setEditMode] = React.useState(false);
  const [editedName, setEditedName] = React.useState('');
  const [isEnabled, setEnabled] = React.useState(false);
  const queryClient = useQueryClient();

  const { data: allOrganizations = [] } = useAllOrganizations();

  const matchedOrganizationId = allOrganizations.find(
    org => org.roleId === selectedRole?.roleId,
  )?.organizationId;

  const { mutate: updateOrganizationMutate, isLoading: isUpdatingOrganization } =
    usePatchOrganization(() => setEditMode(false));

  const { mutate: updateRoleMutate, isLoading: isUpdatingRole } = usePatchRole(() =>
    setEditMode(false),
  );

  const { mutate: deleteRoleMutate } = useMutation(adminAPI.deleteRole, {
    onSuccess: (_, { roleId }) => {
      setSelectedRoleId(null);
      // Update cache after successful delete
      const roles = queryClient.getQueryData<RoleDetails[]>(CACHE_KEYS.rolesDetailed);
      const updatedRoles = roles?.filter(role => role.roleId !== roleId);
      queryClient.setQueryData(CACHE_KEYS.rolesDetailed, updatedRoles);
      // Invalidate so users have correct role_id/role_name
      queryClient.invalidateQueries(CACHE_KEYS.users);
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
      setEditMode(false);
    },
  });

  React.useEffect(() => {
    if (selectedRole) {
      setEditedName(selectedRole.displayName || selectedRole.name);
      setEnabled(selectedRole.enabled);
      setEditMode(false);
    }
  }, [selectedRole]);

  if (!selectedRole?.roleId) {
    return (
      <Typography variant="h4" className={classes.title}>
        All users
      </Typography>
    );
  }

  return (
    <div>
      <div className={classes.titleRow}>
        {isEditMode ? (
          <>
            <Input
              autoFocus
              classes={{ root: classes.title, input: classes.textField }}
              onChange={e => setEditedName(e.target.value)}
              placeholder="Organization name"
              value={editedName}
            />
            <Button
              className={classes.submitBtn}
              color="primary"
              disabled={isUpdatingRole || isUpdatingOrganization}
              type="submit"
              variant="contained"
              onClick={() => {
                if (selectedRole.roleId && matchedOrganizationId) {
                  updateOrganizationMutate({
                    organizationId: matchedOrganizationId,
                    name: editedName,
                  });
                }
              }}
            >
              Save
            </Button>
            <Button
              className={classes.submitBtn}
              color="primary"
              disabled={isUpdatingRole || isUpdatingOrganization}
              onClick={() => setEditMode(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <div>
            <div className={classes.titleRow}>
              <Typography className={classes.title} variant="h4">
                {selectedRole.displayName || selectedRole.name}
              </Typography>
              <IconButton
                aria-label="edit org name"
                className={classes.icon}
                onClick={() => setEditMode(val => !val)}
                size="small"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete org"
                className={classes.icon}
                size="small"
                onClick={() => {
                  const confirmMsg =
                    'Are you sure you want to delete this organization? By doing this, all users will have permissions permanently removed.';

                  if (window.confirm(confirmMsg) && selectedRole.roleId) {
                    deleteRoleMutate({ roleId: selectedRole.roleId });
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <Typography color="textSecondary">{selectedRole.name}</Typography>
          </div>
        )}
      </div>

      <FormControlLabel
        control={
          <Switch
            checked={isEnabled}
            color="primary"
            name="enabled"
            onChange={e => {
              if (isUpdatingRole || isUpdatingOrganization || !selectedRole.roleId) return;

              if (e.target.checked) {
                updateRoleMutate({ role_id: selectedRole.roleId, enabled: true });
                setEnabled(e.target.checked);
              } else {
                if (
                  window.confirm(
                    'Are you sure you want to deactivate this organization? By doing this they will lose access to their dashboard.',
                  )
                ) {
                  updateRoleMutate({ role_id: selectedRole.roleId, enabled: false });
                  setEnabled(e.target.checked);
                }
              }
            }}
          />
        }
        label={isEnabled ? 'Active' : 'Disabled'}
      />
    </div>
  );
};

export default AdminRoleDetails;
