import { PasswordFormValues } from '@types';

type PasswordFormErrors = Omit<Partial<PasswordFormValues>, 'currentPassword'>;

const PASSWORD_REQS = 'Password must be 8 characters or more';

export const FORM_VALIDATION = {
  repeatPasswords: ({ password1, password2 }: PasswordFormErrors) => {
    // Gross but Yup can't do sequential error evaluation reliably and reqs are very specific
    const errors: PasswordFormErrors = {};
    if (password1 !== password2) {
      errors.password2 = 'Passwords do not match';
    }
    if (password1 && !password2) {
      errors.password2 = 'Repeat password required';
    }
    if (!password1 && !password2) {
      errors.password2 = 'Repeat password required';
    }
    if (!password1) {
      errors.password1 = PASSWORD_REQS;
    } else if (password1.length < 8) {
      errors.password1 = PASSWORD_REQS;
    }
    return errors;
  },
};
