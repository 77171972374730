import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  children: React.ReactNode;
  title: string;
  className?: string;
  paddingTop?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      borderRadius: 8,
      border: `1px solid ${theme.palette.kinsaGrey200}`,
    },
    top: {
      borderBottom: `1px solid ${theme.palette.kinsaGrey200}`,
      padding: theme.spacing(2, 3),
    },
    titleText: {
      textTransform: 'uppercase',
    },
    bottom: ({ paddingTop = 2 }: Props) => ({
      padding: theme.spacing(paddingTop, 3, 2),
    }),
  }),
);

const Card = (props: Props) => {
  const { children, title, className } = props;
  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.top}>
        <Typography className={classes.titleText} variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </div>
      <div className={classes.bottom}>{children}</div>
    </div>
  );
};

export default Card;
