import React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import { routes } from './routes';
import {
  AdminDashboard,
  AppProvider,
  ClaimAccount,
  FourOhFour,
  ForgotPassword,
  Login,
  ResetPassword,
  ErrorBoundary,
  UserAccountDashboard,
} from '../components';

const appRoutes = (
  <Switch>
    <Route path={routes.root.value} exact component={AdminDashboard} />
    <Route path={routes.account.value} exact component={UserAccountDashboard} />
    <Route path="*" component={() => <Redirect to={routes.notFound.value} />} />
  </Switch>
);

const baseRoute = (
  <Switch>
    <Route path={routes.login.value} exact component={Login} />
    <Route path={routes.forgotPassword.value} exact component={ForgotPassword} />
    <Route path={routes.resetPassword.value} exact component={ResetPassword} />
    <Route path={routes.claimAccount.value} exact component={ClaimAccount} />
    <Route path={routes.notFound.value} exact component={FourOhFour} />
    <Route
      path={routes.root.value}
      component={(props: RouteComponentProps) => <AppProvider {...props}>{appRoutes}</AppProvider>}
    />
  </Switch>
);

export default <ErrorBoundary>{baseRoute}</ErrorBoundary>;
