import React from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  MoreHoriz as MoreHorizIcon,
  CheckCircle as CheckCircleIcon,
  Send as SendIcon,
} from '@material-ui/icons';

import { AdminRoleUsersTableMenu } from '..';
import { RoleUser } from '@types';

type Props = {
  isAllUsersView: boolean;
  users: RoleUser[];
};

export const ACTIVATION_STATUS_LABEL_MAP = {
  invitation_sent: 'Invitation sent',
  invitation_not_sent: 'Invitation not sent',
  activated: 'Activated',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellHead: {
      ...theme.typography.button,
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    tableCellCentered: {
      display: 'flex',
      alignItems: 'center',
    },
    tableCellMaxWidth: {
      maxWidth: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    noUsers: {
      padding: theme.spacing(5, 2),
      marginTop: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
    },
    checkIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.success.light,
    },
    sendIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  }),
);

const AdminRoleUsersTable = ({ isAllUsersView, users }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = React.useState<RoleUser | null>(null);

  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>, user: RoleUser) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer>
      <Table aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHead}>first name</TableCell>
            <TableCell className={classes.tableCellHead}>last name</TableCell>
            {isAllUsersView && (
              <TableCell className={classes.tableCellHead}>organization</TableCell>
            )}
            <TableCell className={classes.tableCellHead}>email</TableCell>
            {!isAllUsersView && <TableCell className={classes.tableCellHead}>status</TableCell>}
            <TableCell className={classes.tableCellHead} align="center">
              options
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <TableRow key={user.id}>
              <TableCell className={classes.tableCellMaxWidth} component="th" scope="row">
                {user.firstName}
              </TableCell>
              <TableCell className={classes.tableCellMaxWidth}>{user.lastName}</TableCell>
              {isAllUsersView && (
                <TableCell className={classes.tableCellMaxWidth}>{user.roleName}</TableCell>
              )}
              <TableCell className={classes.tableCellMaxWidth}>{user.email}</TableCell>
              {!isAllUsersView && (
                <TableCell>
                  <div className={classes.tableCellCentered}>
                    {user.activationStatus === 'activated' ? (
                      <CheckCircleIcon className={classes.checkIcon} />
                    ) : (
                      <SendIcon className={classes.sendIcon} />
                    )}
                    {ACTIVATION_STATUS_LABEL_MAP[user.activationStatus]}
                  </div>
                </TableCell>
              )}
              <TableCell align="center">
                <IconButton
                  size="small"
                  aria-label="more options"
                  onClick={e => handleOptionsClick(e, user)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedUser && (
        <AdminRoleUsersTableMenu
          user={selectedUser}
          anchorEl={anchorEl}
          handleClose={handleOptionsClose}
        />
      )}

      {users.length <= 0 && (
        <div className={classes.noUsers}>
          <Typography color="textSecondary" align="center">
            Please add new users
          </Typography>
        </div>
      )}
    </TableContainer>
  );
};

export default AdminRoleUsersTable;
