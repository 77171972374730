import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';
import { OrganizationDetails } from '@types';

export const useAllOrganizations = () =>
  useQuery(CACHE_KEYS.organizationsDetailed, adminAPI.getAllOrganizations, {
    select: organizations => organizations,
  });

export const usePatchOrganization = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();

  const patchOrganization = useMutation(adminAPI.patchOrganization, {
    onSuccess: updatedOrganization => {
      // Update cache after successful update
      const organizations = queryClient.getQueryData<OrganizationDetails[]>(
        CACHE_KEYS.organizationsDetailed,
      );
      const updatedOrganizations = organizations?.map(existingOrganization =>
        existingOrganization.organizationId === updatedOrganization.organizationId
          ? { ...existingOrganization, ...updatedOrganization }
          : existingOrganization,
      );

      queryClient.setQueryData(CACHE_KEYS.organizationsDetailed, updatedOrganizations);

      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);

      onSuccessCallback?.();
    },
  });

  return patchOrganization;
};
