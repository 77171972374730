import React from 'react';
import { FieldProps } from 'formik';
import TextField from '@material-ui/core/TextField';

type Props = {
  placeholder: string;
  type: string;
  helperText: string;
};

const TextInput = ({
  field,
  form: { touched, errors, submitCount },
  helperText,
  ...props
}: FieldProps & Props) => {
  const displayError = !!(touched[field.name] && errors[field.name]) && submitCount > 0;
  const displayText = displayError ? errors[field.name] : helperText;

  return <TextField error={displayError} helperText={displayText} {...field} {...props} />;
};

export default TextInput;
