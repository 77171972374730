import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { Formik, FormikHelpers, FormikProps, Form, Field } from 'formik';

import { TextInput } from '../Formik';
import { ErrorText } from '../';
import { FORM_VALIDATION } from 'utils/form';
import { authAPI } from 'api';
import { PasswordFormValues } from '@types';
import { useToastContext } from 'hooks/context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 400,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    btn: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const UpdatePassword = () => {
  const classes = useStyles();
  const [serverError, setServerError] = useState<string | null>(null);
  const { showToast } = useToastContext();

  return (
    <Formik<PasswordFormValues>
      initialValues={{
        currentPassword: '',
        password1: '',
        password2: '',
      }}
      validate={FORM_VALIDATION.repeatPasswords}
      onSubmit={async (
        { currentPassword, password1, password2 }: PasswordFormValues,
        actions: FormikHelpers<PasswordFormValues>,
      ) => {
        try {
          actions.setSubmitting(true);
          await authAPI.updatePassword(currentPassword, password1, password2);
          showToast({
            type: 'success',
            title: 'success!',
            message: 'Password successfully updated.',
          });
          setServerError(null);
          actions.resetForm();
        } catch (err) {
          const errorResp = err.response ? await err.response.json() : null;
          const errorMsg = _.get(errorResp, 'errors[0].message', 'Error updating password.');
          setServerError(errorMsg);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }: FormikProps<PasswordFormValues>) => (
        <Form noValidate className={classes.root}>
          <Field
            required
            name="currentPassword"
            type="password"
            label="Current password"
            margin="normal"
            component={TextInput}
            data-cy="current-password"
          />
          <Field
            required
            name="password1"
            type="password"
            label="New password"
            margin="normal"
            helperText="Password must be 8 characters or more"
            component={TextInput}
            data-cy="new-password-1"
          />
          <Field
            required
            name="password2"
            type="password"
            label="Repeat new password"
            margin="normal"
            component={TextInput}
            data-cy="new-password-2"
          />
          <Button
            className={classes.btn}
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            Update Password
          </Button>

          <ErrorText text={serverError} />
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePassword;
