import { QueryClient } from 'react-query';

export default new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1, // 1 min
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
