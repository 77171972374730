import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { RoleUser } from '@types';

type Props = {
  user: RoleUser | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 400,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
  }),
);

const UserAccountInfo = ({ user }: Props) => {
  const classes = useStyles();

  if (!user) {
    return null;
  }

  return (
    <div className={classes.root}>
      <TextField
        type="email"
        label="Email"
        margin="normal"
        value={user.email}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextField
        type="text"
        label="First name"
        margin="normal"
        value={user.firstName}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextField
        type="text"
        label="Last name"
        margin="normal"
        value={user.lastName}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextField
        type="text"
        label="Organization"
        margin="normal"
        value={user.roleDisplayName || user.roleName || 'n/a'}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </div>
  );
};

export default UserAccountInfo;
