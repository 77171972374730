import { useEffect, useState } from 'react';
import { Formik, FormikHelpers, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';

import { TextInput, CheckboxInput } from '../Formik';
import { FullscreenFormContainer, RouterLink } from '../';
import { routes } from 'router/routes';
import { setDocumentTitle, setAuthCookies } from 'utils/browser';
import { authAPI } from 'api';

type FormValues = {
  password1: string;
  password2: string;
  agreePrivacy: boolean;
  agreeTAC: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxWrapper: {
      margin: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    checkboxGroup: {
      margin: theme.spacing(2, 0),
    },
    checkbox: {
      padding: 0,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    anchor: {
      color: theme.palette.primary.main,
    },
    btn: {
      margin: theme.spacing(2, 0, 3),
    },
    error: {
      margin: theme.spacing(0, 0, 2),
    },
    errorLink: {
      textDecoration: 'underline',
    },
  }),
);

// Form validation schema
const FormSchema = Yup.object().shape({
  password1: Yup.string().min(8, 'Password must be 8 characters or more').required('Required'),
  password2: Yup.string()
    .oneOf([Yup.ref('password1'), null], 'Passwords must match')
    .required('Required'),
  agreePrivacy: Yup.bool().required('Required'),
  agreeTAC: Yup.bool().required('Required'),
});

const ClaimAccount = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);
  const [activationToken, setActivationToken] = useState('');

  useEffect(() => {
    setDocumentTitle(routes.claimAccount.label);
  }, []);

  useEffect(() => {
    const { activation_token } = qs.parse(history.location.search);
    if (typeof activation_token === 'string') {
      setActivationToken(activation_token);
    }
  }, [history.location.search]);

  return (
    <FullscreenFormContainer>
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Welcome to Kinsa Insights
        </Typography>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Create a password to finish signing up
        </Typography>
        <Formik<FormValues>
          initialValues={{
            password1: '',
            password2: '',
            agreePrivacy: false,
            agreeTAC: false,
          }}
          validationSchema={FormSchema}
          onSubmit={async (
            { password1 }: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>,
          ) => {
            try {
              setSubmitting(true);
              const tokenSet = await authAPI.activateUser(activationToken, password1);
              setServerError(false);
              setAuthCookies(tokenSet);
              history.push(routes.root.value);
            } catch (err) {
              setServerError(true);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting }: FormikProps<FormValues>) => (
            <Form noValidate className={classes.form}>
              <Field
                required
                name="password1"
                type="password"
                helperText="Password must be 8 characters or more"
                label="Password"
                margin="normal"
                component={TextInput}
              />
              <Field
                required
                name="password2"
                type="password"
                label="Re-enter Password"
                margin="normal"
                component={TextInput}
              />
              <div className={classes.checkboxGroup}>
                <Field
                  checkboxClassName={classes.checkbox}
                  id="agree-privacy"
                  name="agreePrivacy"
                  label={
                    <div>
                      I agree to the{' '}
                      <a
                        href="https://www.kinsahealth.co/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.anchor}
                      >
                        Privacy Policy
                      </a>
                    </div>
                  }
                  margin="normal"
                  component={CheckboxInput}
                />
                <Field
                  checkboxClassName={classes.checkbox}
                  id="agree-terms-and-conditions"
                  name="agreeTAC"
                  label={
                    <div>
                      I agree to the{' '}
                      <a
                        href="https://www.kinsahealth.co/terms-of-service/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.anchor}
                      >
                        Terms and Conditions
                      </a>
                    </div>
                  }
                  margin="normal"
                  component={CheckboxInput}
                />
              </div>
              <Button
                className={classes.btn}
                type="submit"
                color="primary"
                variant="contained"
                disabled={!values.agreePrivacy || !values.agreeTAC || isSubmitting}
              >
                Complete signup
              </Button>

              {serverError && (
                <>
                  <Typography
                    variant="caption"
                    color="error"
                    align="center"
                    component="div"
                    data-cy="error"
                  >
                    There was an issue activating your account.
                  </Typography>
                  <Typography
                    className={classes.error}
                    variant="caption"
                    color="error"
                    align="center"
                    component="div"
                    data-cy="error"
                  >
                    Please try{' '}
                    <Link
                      className={classes.errorLink}
                      color="error"
                      component={RouterLink}
                      to={routes.forgotPassword.value}
                    >
                      resetting your password
                    </Link>
                    .
                  </Typography>
                </>
              )}

              <Link
                variant="body2"
                align="center"
                color="primary"
                component={RouterLink}
                to={routes.login.value}
              >
                Already have an account?
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </FullscreenFormContainer>
  );
};

export default withRouter(ClaimAccount);
