import _ from 'lodash';
import { deepMapKeys } from 'utils/ui';

import { handleErrorResponse, handleRawResponse, insightsKy } from './base';

import {
  Endpoint,
  EndpointServer,
  Geographies,
  OrganizationDetails,
  OrganizationDetailsServer,
  RegionType,
  RegionTypeServer,
  RoleDetails,
  RoleDetailsServer,
  RoleOverview,
  RoleOverviewServer,
  RoleUser,
  RoleUserServer,
  SignalDetails,
  SignalDetailsServer,
  Team,
  TeamServer,
  ToucanApp,
  ToucanAppServer,
} from '@types';

// #region organizations

type PatchOrganizationServer = {
  category?: string;
  name?: string;
  organization_id: number;
  team_ids?: number[];
};

type PatchOrganizationPayload = {
  category?: string;
  name?: string;
  organizationId: number;
  teamIds?: number[];
};

function patchOrganization({ organizationId, ...payload }: PatchOrganizationPayload) {
  const formattedPayload = deepMapKeys<PatchOrganizationServer>(payload, (key: string) =>
    _.snakeCase(key),
  );

  return insightsKy
    .patch(`admin/organizations/${organizationId}`, {
      body: JSON.stringify(formattedPayload),
    })
    .then(res => res.json())
    .then((res: OrganizationDetailsServer[]) => deepMapKeys<OrganizationDetails>(res));
}

function getAllOrganizations() {
  return insightsKy
    .get('admin/organizations')
    .then(res => res.json())
    .then((res: OrganizationDetailsServer[]) =>
      res.map(organization => deepMapKeys<OrganizationDetails>(organization)),
    );
}

function getAllSignals() {
  return insightsKy
    .get('admin/signals')
    .then(res => res.json())
    .then((res: SignalDetailsServer[]) => res.map(signal => deepMapKeys<SignalDetails>(signal)));
}

type BulkAssignPayload = {
  organizationId: number | null;
  userIds: number[];
};

function bulkAssignUsersToOrganization({ organizationId, userIds }: BulkAssignPayload) {
  return insightsKy
    .post(`admin/organizations/${organizationId}/user-bulk-assign`, {
      body: JSON.stringify({ user_ids: userIds }),
    })
    .then(res => res.json())
    .then((res: RoleUserServer[]) => res.map(user => deepMapKeys<RoleUser>(user)));
}

type BulkAssignCsvUploadPayload = {
  organizationId: number;
  file: File;
};

function bulkAssignCsvUpload({ organizationId, file }: BulkAssignCsvUploadPayload) {
  const formData = new FormData();
  formData.append('file', file);

  return insightsKy
    .post(`admin/organizations/${organizationId}/user-bulk-assign-csv`, {
      body: formData,
    })
    .then(res => res.json())
    .then((res: RoleUserServer[]) => res.map(user => deepMapKeys<RoleUser>(user)));
}

// #endregion organizations

// #region roles

function getAllRoles() {
  return insightsKy
    .get('admin/roles')
    .then(res => res.json())
    .then((res: RoleDetailsServer[]) => res.map(role => deepMapKeys<RoleDetails>(role)));
}

type CreateRolePayload = {
  category: string;
  display_name: string;
  name: string;
  enabled: boolean;
};

function postRole(payload: CreateRolePayload) {
  return insightsKy
    .post('admin/roles', {
      body: JSON.stringify(payload),
    })
    .then(res => res.json())
    .then((res: RoleOverviewServer) => deepMapKeys<RoleOverview>(res));
}

type PatchRolePayload = {
  role_id: number;
  category?: string;
  display_name?: string;
  enabled?: boolean;
  days_in_future?: number;
  endpoints?: number[];
  toucan_domains?: number[];
  start_date?: string;
  signals?: number[];
};

function patchRole({ role_id, ...payload }: PatchRolePayload) {
  return insightsKy
    .patch(`admin/roles/${role_id}`, {
      body: JSON.stringify(payload),
    })
    .then(res => res.json())
    .then(res => deepMapKeys<RoleDetails>(res));
}

function deleteRole({ roleId }: { roleId: number }) {
  return insightsKy.delete(`admin/roles/${roleId}`).then(() => true);
}

// #endregion roles

// #region users

function getAllUsers() {
  return insightsKy
    .get('admin/users')
    .then(res => res.json())
    .then((res: RoleUserServer[]) => res.map(user => deepMapKeys<RoleUser>(user)));
}

type PostUserPayload = {
  first_name: string;
  last_name: string;
  email: string;
  organization_id?: number | null;
};

function postUser(payload: PostUserPayload): Promise<RoleUser> {
  return insightsKy
    .post('admin/user', {
      body: JSON.stringify(payload),
    })
    .then(handleRawResponse)
    .then((user: RoleUserServer) => deepMapKeys<RoleUser>(user))
    .catch(handleErrorResponse);
}

type PatchUserPayload = { id: number } & Partial<PostUserPayload>;

function patchUser({ id, ...payload }: PatchUserPayload) {
  return insightsKy
    .patch(`admin/user/${id}`, {
      body: JSON.stringify(payload),
    })
    .then(handleRawResponse)
    .then((user: RoleUserServer) => deepMapKeys<RoleUser>(user))
    .catch(handleErrorResponse);
}

function deleteUser({ id }: { id: number }) {
  return insightsKy.delete(`admin/user/${id}`).then(() => true);
}

// #endregion users

// #region teams

type PostTeamPayload = {
  category: string;
  description: string;
  name: string;
};

type PatchTeamServer = {
  category?: string;
  name?: string;
  description?: string;
  team_id: number;
};

type PatchTeamPayload = {
  category?: string;
  name?: string;
  description?: string;
  teamId: number;
};

function getAllTeams() {
  return insightsKy
    .get('admin/teams')
    .then(res => res.json())
    .then((res: TeamServer[]) => res.map(endpoint => deepMapKeys<Team>(endpoint)));
}

function postTeam(payload: PostTeamPayload): Promise<Team> {
  return insightsKy
    .post('admin/teams', {
      body: JSON.stringify(payload),
    })
    .then(handleRawResponse)
    .then((team: TeamServer) => deepMapKeys<Team>(team))
    .catch(handleErrorResponse);
}

function patchTeam({ teamId, ...payload }: PatchTeamPayload) {
  const formattedPayload = deepMapKeys<PatchTeamServer>(payload, (key: string) => _.snakeCase(key));

  return insightsKy
    .patch(`admin/teams/${teamId}`, {
      body: JSON.stringify(formattedPayload),
    })
    .then(handleRawResponse)
    .then((res: TeamServer) => deepMapKeys<Team>(res))
    .catch(handleErrorResponse);
}

function deleteTeam({ teamId }: { teamId: number }) {
  return insightsKy.delete(`admin/teams/${teamId}`).then(() => true);
}

// #endregion teams

// #region toucantoco

function getAllToucanDomains() {
  return insightsKy
    .get('admin/toucan-domains')
    .then(res => res.json())
    .then((res: ToucanAppServer[]) => res.map(domain => deepMapKeys<ToucanApp>(domain)));
}

type AssignRolesToToucanDomainPayload = {
  toucanDomainId: number;
  roleIds: number[];
};

function bulkAssignRolesToToucanDomains({
  toucanDomainId,
  roleIds,
}: AssignRolesToToucanDomainPayload) {
  return insightsKy
    .post(`admin/toucan-domains/${toucanDomainId}/assign-roles`, {
      body: JSON.stringify({ role_ids: roleIds }),
    })
    .then(res => res.json())
    .then((res: RoleDetailsServer[]) => res.map(role => deepMapKeys<RoleDetails>(role)))
    .catch(handleErrorResponse);
}

// #endregion toucantoco

// #region endpoints

function getAllEndpoints() {
  return insightsKy
    .get('admin/endpoints')
    .then(res => res.json())
    .then((res: EndpointServer[]) => res.map(endpoint => deepMapKeys<Endpoint>(endpoint)));
}

type AssignRolesToEndpointPayload = {
  endpointId: number;
  roleIds: string[];
};

function bulkAssignRolesToEndpoints({ endpointId, roleIds }: AssignRolesToEndpointPayload) {
  return insightsKy
    .post(`admin/endpoints/${endpointId}/assign-roles`, {
      body: JSON.stringify({ role_ids: roleIds }),
    })
    .then(res => res.json())
    .then((res: RoleDetailsServer[]) => res.map(role => deepMapKeys<RoleDetails>(role)))
    .catch(handleErrorResponse);
}

// #endregion endpoints

// #region geo

function getAllRegionTypes() {
  return insightsKy
    .get(`admin/region-types`)
    .then(res => res.json())
    .then((res: RegionTypeServer[]) => res.map(rt => deepMapKeys<RegionType>(rt)));
}

function getGeographiesByRole(roleId: RoleDetails['roleId']) {
  return insightsKy
    .get(`admin/roles/${roleId}/geography`)
    .then(res => res.json())
    .then((res: Geographies) => res);
}

type UpdateGeographiesPayload = {
  roleId: RoleDetails['roleId'];
  custom: boolean;
  geography: string;
};

function setGeographiesByRole({ roleId, ...payload }: UpdateGeographiesPayload) {
  return insightsKy
    .post(`admin/roles/${roleId}/geography`, {
      body: JSON.stringify(payload),
    })
    .then(res => res.json())
    .then((res: Geographies) => res)
    .catch(handleErrorResponse);
}

type ResetGeographiesPayload = {
  roleId: RoleDetails['roleId'];
};

function resetGeographiesByRole({ roleId }: ResetGeographiesPayload) {
  return insightsKy
    .post(`admin/roles/${roleId}/geography/reset`)
    .then(res => res.json())
    .then((res: Geographies) => res)
    .catch(handleErrorResponse);
}

// #endregion geo

export default {
  bulkAssignCsvUpload,
  bulkAssignRolesToEndpoints,
  bulkAssignRolesToToucanDomains,
  bulkAssignUsersToOrganization,
  deleteRole,
  deleteTeam,
  deleteUser,
  getAllEndpoints,
  getAllOrganizations,
  getAllSignals,
  getAllRegionTypes,
  getAllRoles,
  getAllTeams,
  getAllToucanDomains,
  getAllUsers,
  getGeographiesByRole,
  patchOrganization,
  patchRole,
  patchTeam,
  patchUser,
  postRole,
  postTeam,
  postUser,
  resetGeographiesByRole,
  setGeographiesByRole,
};
