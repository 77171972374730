import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import { AdminTeamsByCategory } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(4),
    },
    divider: {
      margin: theme.spacing(1.5, 0),
    },
    root: {
      margin: theme.spacing(5, 'auto'),
      maxWidth: theme.breakpoints.values.md,
    },
    title: {
      fontWeight: 700,
    },
  }),
);

const AdminTeamsPanel = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Teams
      </Typography>
      <div className={classes.content}>
        <AdminTeamsByCategory dividerEl={<Divider className={classes.divider} />} />
      </div>
    </div>
  );
};

export default AdminTeamsPanel;
