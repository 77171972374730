import React from 'react';
import { FieldProps } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

type Props = {
  className: string;
  checkboxClassName: string;
  label: string;
  id: string;
};

const CheckboxInput = ({ field, form, ...props }: FieldProps & Props) => {
  return (
    <FormControlLabel
      className={props.className}
      control={
        <Checkbox
          name={field.name}
          id={props.id}
          className={props.checkboxClassName}
          onChange={field.onChange}
          checked={field.value}
          value={field.value}
          color="primary"
        />
      }
      label={props.label}
    />
  );
};

export default CheckboxInput;
