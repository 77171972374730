import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { RoleDetails } from '@types';
import { AdminRoleDetails, AdminRolePermissions, AdminRoleUsers } from '..';

type Props = {
  isAllUsersView: boolean;
  selectedRole?: RoleDetails;
  setSelectedRoleId: (roleId: RoleDetails['roleId']) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(5, 'auto'),
      maxWidth: theme.breakpoints.values.md,
    },
  }),
);

const AdminRolePanel = ({ isAllUsersView, selectedRole, setSelectedRoleId }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AdminRoleDetails
        isAllUsersView={isAllUsersView}
        selectedRole={selectedRole}
        setSelectedRoleId={setSelectedRoleId}
      />
      {!isAllUsersView && selectedRole && <AdminRolePermissions selectedRole={selectedRole} />}
      <AdminRoleUsers isAllUsersView={isAllUsersView} selectedRole={selectedRole} />
    </div>
  );
};

export default AdminRolePanel;
