import DateFnsUtils from '@date-io/date-fns';

import { getUtcDate } from 'utils/ui';

export default class LocalizedUtils extends DateFnsUtils {
  date(date: Date) {
    if (date) {
      // Need to display calendar in UTC hours since that's what's represented by /latest_date_available
      // https://stackoverflow.com/a/14006555/4469064
      return getUtcDate(date);
    }
    return new Date();
  }
}
