import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { AdminRoleDomainsDialog, ExpandableList } from 'components';
import { RoleDetails } from '@types';
import { adminAPI } from 'api';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  dividerEl: React.ReactNode;
  roles: RoleDetails[];
};

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      minWidth: 380,
    },
  }),
);

const AdminDomainsByRole = ({ dividerEl, roles }: Props) => {
  const classes = useStyles();

  const [editedRole, setEditedRole] = useState<RoleDetails | null>(null);

  const { data: allToucanApps = [] } = useQuery(
    CACHE_KEYS.toucanDomains,
    adminAPI.getAllToucanDomains,
    {
      select: apps => apps.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    },
  );

  return (
    <>
      {roles.map(role => {
        const selectedSourceCount = role.toucanAppsList.reduce(
          (acc, { toucanDomainsList }) => (acc += toucanDomainsList.length),
          0,
        );

        return (
          <React.Fragment key={role.roleId}>
            <ExpandableList
              title={`${role.displayName || role.name} (${selectedSourceCount})`}
              listItems={role.toucanAppsList.map(({ name }) => name)}
              buttonText="Select Data Sources"
              handleClickButton={() => setEditedRole(role)}
            />
            {dividerEl}
          </React.Fragment>
        );
      })}

      <AdminRoleDomainsDialog
        className={classes.dialogContainer}
        open={Boolean(editedRole)}
        close={() => setEditedRole(null)}
        allDomainsByToucanApp={allToucanApps}
        selectedRole={editedRole}
        title={`Select Data Sources for ${editedRole?.displayName}`}
      />
    </>
  );
};

export default AdminDomainsByRole;
