import { insightsKy } from './base';
import { TokenSet, TokenSetServer, RoleUser, RoleUserServer } from '@types';
import { deepMapKeys } from 'utils/ui';

type UserId = { id: string };

// #region AUTH

function login(email: string, password: string): Promise<TokenSet & UserId> {
  return insightsKy
    .post('auth/login', {
      body: JSON.stringify({
        email,
        password,
      }),
    })
    .then(res => res.json())
    .then((tokenSet: TokenSetServer & UserId) => deepMapKeys<TokenSet & UserId>(tokenSet));
}

function refreshTokenSet(): Promise<TokenSet> {
  return insightsKy
    .post('auth/refresh')
    .then(res => res.json())
    .then((tokenSet: TokenSetServer) => deepMapKeys<TokenSet>(tokenSet));
}

function getUserInfo(): Promise<RoleUser> {
  return insightsKy
    .get('auth/me')
    .then(res => res.json())
    .then((res: RoleUserServer) => deepMapKeys<RoleUser>(res));
}

function activateUser(activationToken: string, newPassword: string): Promise<TokenSet> {
  return insightsKy
    .post('auth/activate', {
      body: JSON.stringify({
        activation_token: activationToken,
        new_password: newPassword,
      }),
    })
    .then(res => res.json())
    .then((tokenSet: TokenSetServer) => deepMapKeys<TokenSet>(tokenSet));
}

function updatePassword(
  oldPassword: string,
  newPassword1: string,
  newPassword2: string,
): Promise<RoleUser> {
  return insightsKy
    .patch('auth/me/password', {
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword1,
        new_password2: newPassword2,
      }),
    })
    .then(res => res.json())
    .then((res: RoleUserServer) => deepMapKeys<RoleUser>(res));
}

function forgotPassword(email: string): Promise<boolean> {
  return insightsKy
    .post('auth/recovery-email', {
      body: JSON.stringify({
        email,
      }),
    })
    .then(res => res.json())
    .then((success: boolean) => success);
}

function sendActivationEmail(email: string): Promise<boolean> {
  return insightsKy
    .post('auth/activation-email', {
      body: JSON.stringify({
        email,
      }),
    })
    .then(res => res.json())
    .then((success: boolean) => success);
}

function recoverAccount(recoveryToken: string, password: string): Promise<TokenSet & UserId> {
  return insightsKy
    .post('auth/recover-account', {
      body: JSON.stringify({
        recovery_token: recoveryToken,
        new_password: password,
      }),
    })
    .then(res => res.json())
    .then((tokenSet: TokenSetServer & UserId) => deepMapKeys<TokenSet & UserId>(tokenSet));
}

// #endregion AUTH

export default {
  login,
  refreshTokenSet,
  getUserInfo,
  activateUser,
  updatePassword,
  recoverAccount,
  forgotPassword,
  sendActivationEmail,
};
