import React from 'react';
import { Formik, FormikConfig, Form, FormikValues } from 'formik';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  formikConfig: FormikConfig<FormikValues>;
  actions: React.ReactNode;
  children: React.ReactNode;
  label: string;
  title: string;
} & DialogProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 380,
    },
    actions: {
      alignItems: 'stretch',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(4, 3, 3),
    },
    title: {
      fontWeight: 700,
      marginTop: theme.spacing(2),
      minWidth: 216,
    },
  }),
);

const FormikDialog = ({ formikConfig, children, title, label, actions, ...props }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.container }}
      aria-labelledby={label}
      scroll="paper"
      {...props}
    >
      <Formik {...formikConfig}>
        <Form noValidate>
          <DialogTitle disableTypography id={label}>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions className={classes.actions}>{actions}</DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default FormikDialog;
