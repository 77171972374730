import { useState, useMemo, useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { APP_HEADER_HEIGHT } from 'utils/constants';
import { RoleOverview, AdminSidebarState } from '@types';
import {
  AdminSidebar,
  AdminRolePanel,
  AdminDomainsPanel,
  AdminEndpointsPanel,
  AdminTeamsPanel,
} from '../';
import { useAllRoles } from 'hooks/roles';

const ADMIN_SIDEBAR_WIDTH = 285;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: APP_HEADER_HEIGHT,
      display: 'flex',
      minHeight: '100%',
    },
    sidebarContainer: {
      marginTop: APP_HEADER_HEIGHT,
      width: ADMIN_SIDEBAR_WIDTH,
      position: 'fixed',
      top: 0,
      bottom: 0,
      overflow: 'auto',
    },
    contentContainer: {
      marginLeft: ADMIN_SIDEBAR_WIDTH,
      width: '100%',
      flex: 1,
      padding: theme.spacing(0, 3),
      backgroundColor: theme.palette.background.paper,
      overflowX: 'hidden',
    },
  }),
);

const AdminDashboard = () => {
  const classes = useStyles();
  const [selectedSidebarOption, setSelectedSidebarOption] = useState<AdminSidebarState>({
    roleId: null,
    type: 'role',
  });
  const { data: allRoles = [] } = useAllRoles();

  const setSelectedRoleId = useCallback((roleId: RoleOverview['roleId']) => {
    setSelectedSidebarOption({ roleId, type: 'role' });
  }, []);

  const selectedRole = useMemo(
    () => allRoles.find(role => role.roleId === selectedSidebarOption.roleId),
    [selectedSidebarOption, allRoles],
  );

  const isAllUsersView = selectedSidebarOption.type === 'role' && !selectedRole?.roleId;

  return (
    <div className={classes.root}>
      <div className={classes.sidebarContainer}>
        <AdminSidebar
          roles={allRoles}
          isAllUsersView={isAllUsersView}
          selectedSidebarOption={selectedSidebarOption}
          setSelectedSidebarOption={setSelectedSidebarOption}
        />
      </div>

      <div className={classes.contentContainer}>
        {selectedSidebarOption.type === 'role' && (
          <AdminRolePanel
            isAllUsersView={isAllUsersView}
            selectedRole={selectedRole}
            setSelectedRoleId={setSelectedRoleId}
          />
        )}

        {selectedSidebarOption.type === 'domains' && <AdminDomainsPanel />}
        {selectedSidebarOption.type === 'endpoints' && <AdminEndpointsPanel />}
        {selectedSidebarOption.type === 'teams' && <AdminTeamsPanel />}
      </div>
    </div>
  );
};

export default AdminDashboard;
