import Cookie from 'js-cookie';

import { TokenSet } from '@types';
import { COOKIE_NAMES } from './constants';

export const setDocumentTitle = (title: string, suffix = ' | Kinsa Insights') => {
  document.title = title + suffix;
};

export const setAuthCookies = (tokenSet: TokenSet | undefined) => {
  if (tokenSet) {
    Cookie.set(COOKIE_NAMES.accessToken, tokenSet.accessToken);
    Cookie.set(COOKIE_NAMES.refreshToken, tokenSet.refreshToken);
  }
};

export const deleteAuthCookies = () => {
  Cookie.remove(COOKIE_NAMES.accessToken);
  Cookie.remove(COOKIE_NAMES.refreshToken);
};
