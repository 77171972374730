import { useContext } from 'react';

import { UserContext, ToastContext } from 'config/context';

export function useCurrentUser() {
  return useContext(UserContext);
}

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToastContext must be used within ToastProvider');
  }

  return context;
};
