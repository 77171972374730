import { useState, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

import { Team } from '@types';
import { DialogCustom } from 'components';

import { VIEW_MODE } from '../../AdminTeamsDialog/dialogs/constants';
import { SharedProps, TeamsByCategoryCollection } from '../../AdminTeamsDialog/types';
import { AdminNoTeamsExistDialog } from '../../AdminTeamsDialog/dialogs';

type Props = Omit<SharedProps, 'className'> & {
  category: string;
  teamsByCategory: TeamsByCategoryCollection;
  setSelectedTeam: Dispatch<SetStateAction<Team | undefined>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'flex',
      flexDirection: 'column',
    },
    subtext: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    teamName: {
      fontSize: '15px',
      fontWeight: 500,
      marginBottom: theme.spacing(0.5),
    },
    listItem: {
      justifyContent: 'space-between',
    },
    listItemText: {
      marginTop: 0,
      maxWidth: 240,
    },
    editActions: {
      alignSelf: 'flex-start',
      visibility: 'hidden',
    },
    icon: {
      minWidth: 'unset',
      marginLeft: theme.spacing(1.5),
      cursor: 'pointer',
    },
    visible: {
      visibility: 'visible',
    },
  }),
);

const EditTeamsDialog = ({
  close,
  category,
  open,
  setViewMode,
  setSelectedTeam,
  teamsByCategory,
}: Props) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState<string | null>(null);

  const handleClose = () => {
    setSelectedTeam(undefined);
    setHovered(null);
    close();
  };

  const teamList = teamsByCategory[category];

  if (!teamList || !teamList.length) {
    return (
      <AdminNoTeamsExistDialog
        close={close}
        open={open}
        title={`${category} Teams`}
        message="There are currently no existing teams within this vertical. Please create a new team to
          continue."
        setViewMode={setViewMode}
      />
    );
  }

  return (
    <>
      <DialogCustom
        label="edit teams"
        onClose={handleClose}
        open={open}
        title={`${category} Teams`}
        titleAction={'Create new team'}
        titleActionClickHandler={() => setViewMode(VIEW_MODE.createTeam)}
        actions={
          <Button color="primary" onClick={handleClose} variant="contained">
            Close
          </Button>
        }
      >
        <List>
          {teamList.map((team, i) => (
            <ListItem
              key={i}
              disableGutters
              className={classes.listItem}
              onMouseEnter={() => setHovered(`${team.name}-${i}`)}
              onMouseLeave={() => setHovered(null)}
            >
              <ListItemText
                className={classes.listItemText}
                classes={{ primary: classes.teamName, secondary: classes.subtext }}
                primary={team.name}
                secondary={team.description ?? ''}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
              <div
                className={classNames(classes.editActions, {
                  [classes.visible]: hovered === `${team.name}-${i}`,
                })}
              >
                <ListItemIcon
                  className={classes.icon}
                  onClick={() => {
                    setSelectedTeam(team);
                    setViewMode(VIEW_MODE.editTeam);
                  }}
                >
                  <EditIcon />
                </ListItemIcon>
                <ListItemIcon
                  className={classes.icon}
                  onClick={() => {
                    setSelectedTeam(team);
                    setViewMode(VIEW_MODE.deleteTeam);
                  }}
                >
                  <DeleteIcon />
                </ListItemIcon>
              </div>
            </ListItem>
          ))}
        </List>
      </DialogCustom>
    </>
  );
};

export default EditTeamsDialog;
