import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

type Props = {
  text: string | undefined | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      textAlign: 'center',
      margin: theme.spacing(2, 0),
      whiteSpace: 'pre-wrap',
    },
  }),
);

const ErrorText = ({ text }: Props) => {
  const classes = useStyles();

  return (
    <div>
      {text && (
        <Typography
          className={classes.error}
          variant="caption"
          color="error"
          align="center"
          component="div"
          data-cy="error"
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export default ErrorText;
