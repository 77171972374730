import { Button, Typography } from '@material-ui/core';

import { DialogCustom } from 'components';

type Props = {
  title?: string;
  actionText?: string;
  errorMessage?: string;
  close: () => void;
  open: boolean;
  onActionClick: () => void;
};

const defaultErrorMessage =
  'Sorry, we had some technical problems with your last operation. Please try again in a few minutes';

const ErrorDialog = ({
  title = 'Ooops! Something went wrong',
  actionText = 'Ok',
  errorMessage = defaultErrorMessage,
  close,
  open,
  onActionClick,
}: Props) => (
  <DialogCustom
    label={title}
    onClose={close}
    open={open}
    title={title}
    actions={
      <>
        <Button color="primary" onClick={onActionClick}>
          {actionText}
        </Button>
      </>
    }
  >
    <Typography>{errorMessage}</Typography>
  </DialogCustom>
);

export default ErrorDialog;
