import { Button, Typography } from '@material-ui/core';

import { VIEW_MODE } from './constants';
import { SharedProps } from '../types';
import { DialogCustom } from '../..';

type Props = SharedProps & {
  title: string;
  message: string;
};

const AdminNoTeamsExistDialog = ({ close, open, title, message, setViewMode }: Props) => {
  const handleClose = () => setViewMode(VIEW_MODE.createTeam);

  return (
    <DialogCustom
      label={title}
      onClose={close}
      open={open}
      title={title}
      actions={
        <>
          <Button color="primary" onClick={close}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleClose} variant="contained">
            Create a team
          </Button>
        </>
      }
    >
      <Typography>{message}</Typography>
    </DialogCustom>
  );
};

export default AdminNoTeamsExistDialog;
