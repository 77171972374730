import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from 'react-query';

import './config/sentry';
import theme from './config/muiTheme';
import LocalizedUtils from './config/date';
import { ToastProvider } from './config/context';
import queryClient from './config/reactQuery';
import routes from './router';
import './index.scss';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={LocalizedUtils}>
        <ToastProvider>
          <Router>
            <CssBaseline />
            {routes}
          </Router>
        </ToastProvider>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
