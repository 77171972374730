import { useMemo, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Collapse,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ArrowDropDown, ArrowRight } from '@material-ui/icons';

type CheckboxGroupOption = {
  parentId: string;
  parentLabel: string;
  children: { id: string; label: string }[];
};

type Props = {
  option: CheckboxGroupOption;
  selectedOptions: string[];
  maxWidth?: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleParentChange: (e: React.ChangeEvent<HTMLInputElement>, option: CheckboxGroupOption) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    listItemButton: {
      '&:hover': {
        background: 'transparent',
      },
    },
    listItemRoot: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    listItemText: {
      marginLeft: theme.spacing(1),
    },
    parentLabel: {
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    disabled: {
      visibility: 'hidden',
    },
    listItemSecondary: {
      position: 'unset',
      transform: 'none',
    },
    checkbox: {
      color: theme.palette.primary.main,
      padding: '3px',
    },
    childrenContainer: ({ maxWidth }: Props) => ({
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(8),
      maxWidth: maxWidth,
    }),
  }),
);

const CheckboxGroup = (props: Props) => {
  const { option, selectedOptions, handleChange, handleParentChange } = props;
  const classes = useStyles(props);

  const [open, setOpen] = useState(false);

  const allChildOptionsChecked = useMemo(
    () => option.children.every(({ id }) => selectedOptions.includes(id)),
    [selectedOptions, option],
  );

  const someChildOptionsChecked = useMemo(
    () => option.children.some(({ id }) => selectedOptions.includes(id)),
    [selectedOptions, option],
  );

  const isAllChecked =
    option.children.length > 0 && selectedOptions.length > 0 && allChildOptionsChecked;
  const isIndeterminateChecked = !isAllChecked && someChildOptionsChecked;

  const handleListItemClick = () => setOpen(!open);

  const children = option.children.map(({ id, label }) => (
    <FormControlLabel
      key={id}
      label={label}
      control={
        <Checkbox
          color="primary"
          checked={selectedOptions.includes(id)}
          onChange={handleChange}
          name={id}
        />
      }
    />
  ));

  return (
    <>
      <ListItem
        className={classes.listItemButton}
        classes={{ container: classes.listItemContainer, root: classes.listItemRoot }}
        disableGutters
        disabled={!option.children.length}
        button
        disableRipple
        onClick={handleListItemClick}
      >
        {open ? (
          <ArrowDropDown className={!option.children.length ? classes.disabled : ''} />
        ) : (
          <ArrowRight className={!option.children.length ? classes.disabled : ''} />
        )}
        <ListItemText
          className={classes.listItemText}
          disableTypography
          primary={
            <Typography className={classes.parentLabel}>
              {option.parentLabel} ({option.children.length})
            </Typography>
          }
        />
        <ListItemSecondaryAction className={classes.listItemSecondary}>
          <Checkbox
            color="primary"
            className={classes.checkbox}
            checked={isAllChecked}
            indeterminate={isIndeterminateChecked}
            onChange={e => handleParentChange(e, option)}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.childrenContainer}>{children}</div>
      </Collapse>
    </>
  );
};

export default CheckboxGroup;
