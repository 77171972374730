import { useState } from 'react';
import { Button, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';

import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';
import { DialogCustom } from '../..';
import { SharedProps } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      border: `1px solid ${theme.palette.kinsaGrey200}`,
      borderRadius: 4,
      padding: theme.spacing(1.5),
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
  }),
);

const UploadJsonDialog = ({ className, selectedRole, open, close, setViewMode }: SharedProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [jsonFile, setJsonFile] = useState<File>();
  const [hasParseError, setParseError] = useState(false);

  const {
    mutate: updateGeographies,
    isLoading: isUpdatingGeographies,
    error: serverError,
  } = useMutation(adminAPI.setGeographiesByRole, {
    onSuccess: newGeographies => {
      queryClient.setQueryData([CACHE_KEYS.geography, selectedRole.roleId], newGeographies);
      setViewMode('view json');
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      setJsonFile(file);
      setParseError(false);
    }
  };

  const handleFileUpload = () => {
    if (!jsonFile) {
      return;
    }

    // Parse JSON
    const fileReader = new FileReader();

    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        try {
          const parsedJSON = JSON.parse(fileReader.result);
          updateGeographies({
            roleId: selectedRole.roleId,
            custom: true,
            geography: JSON.stringify(parsedJSON),
          });
        } catch (err) {
          console.warn(err);
          setParseError(true);
        }
      }
    };

    fileReader.readAsText(jsonFile);
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      transitionDuration={0}
      open={open}
      maxWidth="xs"
      onClose={close}
      title="Upload JSON file"
      label="upload json file"
      actions={
        <>
          <Button onClick={close} color="primary" disabled={isUpdatingGeographies}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!jsonFile || isUpdatingGeographies}
            onClick={handleFileUpload}
          >
            Upload
          </Button>
        </>
      }
    >
      <form className={classes.form}>
        <input
          type="file"
          id="json-upload"
          name="json-upload"
          accept="application/json"
          multiple={false}
          onChange={handleFileChange}
        />
      </form>

      {hasParseError && (
        <Typography className={classes.errorMsg} color="error">
          There was an error parsing the JSON. Try running it through a{' '}
          <Link
            href="https://jsonformatter.curiousconcept.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            validator
          </Link>{' '}
          to debug.
        </Typography>
      )}

      {serverError instanceof Error && (
        <Typography className={classes.errorMsg} color="error">
          {serverError.message}
        </Typography>
      )}
    </DialogCustom>
  );
};

export default UploadJsonDialog;
