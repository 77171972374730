import { useState, useCallback, useMemo, ChangeEvent } from 'react';

type Option = {
  id: string;
};

type CheckboxGroupOption = {
  parentId: string;
  parentLabel: string;
  children: { id: string; label: string }[];
};

export const useCheckboxes = (options: Option[] = []) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isAllChecked = selectedOptions.length === options.length && selectedOptions.length > 0;
  const isIndeterminateChecked = !isAllChecked && selectedOptions.length > 0;

  const handleChangeAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        // Check
        const newSelectedOptions = options.map(d => d.id);
        setSelectedOptions(newSelectedOptions);
      } else {
        // Uncheck
        setSelectedOptions([]);
      }
    },
    [options],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const optionId = e.target.name;

      if (e.target.checked) {
        // Check
        const newSelectedOptions = new Set([...selectedOptions, optionId]);
        setSelectedOptions([...newSelectedOptions]);
      } else {
        // Uncheck
        const newSelectedOptions = selectedOptions.filter(d => d !== optionId);
        setSelectedOptions(newSelectedOptions);
      }
    },
    [selectedOptions],
  );

  return {
    isAllChecked,
    isIndeterminateChecked,
    handleChange,
    handleChangeAll,
    selectedOptions,
    setSelectedOptions,
  };
};

export const useNestedCheckboxes = (options: CheckboxGroupOption[] = []) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const allChildren = useMemo(
    () => options.flatMap(({ children }) => children).map(({ id }) => id),
    [options],
  );

  const isAllChecked = selectedOptions.length === allChildren.length && selectedOptions.length > 0;
  const isIndeterminateChecked = !isAllChecked && selectedOptions.length > 0;

  const handleChangeAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      e.target.checked ? setSelectedOptions(allChildren) : setSelectedOptions([]),
    [allChildren],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const optionId = e.target.name;

      if (e.target.checked) {
        // Check
        const newSelectedOptions = new Set([...selectedOptions, optionId]);
        setSelectedOptions([...newSelectedOptions]);
      } else {
        // Uncheck
        const newSelectedOptions = selectedOptions.filter(id => id !== optionId);
        setSelectedOptions(newSelectedOptions);
      }
    },
    [selectedOptions],
  );

  const handleParentChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, parent: CheckboxGroupOption) => {
      const optionIds = parent.children.map(({ id }) => id);

      if (e.target.checked) {
        // Check
        const newSelectedOptions = new Set([...selectedOptions, ...optionIds]);
        setSelectedOptions([...newSelectedOptions]);
      } else {
        // Uncheck
        const newSelectedOptions = selectedOptions.filter(option => !optionIds.includes(option));
        setSelectedOptions(newSelectedOptions);
      }
    },
    [selectedOptions],
  );

  return {
    isAllChecked,
    isIndeterminateChecked,
    handleChange,
    handleChangeAll,
    handleParentChange,
    selectedOptions,
    setSelectedOptions,
  };
};
