import { useEffect, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';

import { RoleDetails, Endpoint } from '@types';
import { useCheckboxes } from 'hooks/forms';
import { usePatchRole } from 'hooks/roles';
import { DialogCustom } from '..';

type Props = {
  selectedRole: RoleDetails;
  open: boolean;
  close: () => void;
  className: string;
  allEndpoints: Endpoint[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginLeft: theme.spacing(1),
      width: '1.25rem',
      height: '1.25rem',
    },
  }),
);

const AdminRoleEndpointsDialog = ({
  className,
  selectedRole,
  open,
  close,
  allEndpoints,
}: Props) => {
  const classes = useStyles();

  const checkboxOptions = useMemo(
    () =>
      allEndpoints.map(endpoint => ({
        id: endpoint.endpointId.toString(),
        label: endpoint.displayName,
      })),
    [allEndpoints],
  );

  const {
    isAllChecked,
    isIndeterminateChecked,
    selectedOptions,
    setSelectedOptions,
    handleChange,
    handleChangeAll,
  } = useCheckboxes(checkboxOptions);

  const patchRole = usePatchRole();

  useEffect(() => {
    if (open) {
      // Reset when opening dialog
      setSelectedOptions(
        selectedRole.endpointsList.map(endpoint => endpoint.endpointId.toString()),
      );
    }
  }, [open, setSelectedOptions, selectedRole]);

  const handleSubmit = async () => {
    try {
      if (selectedRole.roleId) {
        await patchRole.mutateAsync({
          role_id: selectedRole.roleId,
          endpoints: selectedOptions.map(Number),
        });
        close();
      }
    } catch (err) {
      console.warn('Error updating role', err);
    }
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      open={open}
      onClose={close}
      title={`Select Endpoints (${selectedOptions.length}/${checkboxOptions.length})`}
      label="select endpoints"
      actions={
        <>
          <Button onClick={close} color="primary" disabled={patchRole.isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={patchRole.isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl component="fieldset" data-testid="endpoints-dialog">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={isIndeterminateChecked}
                checked={isAllChecked}
                onChange={handleChangeAll}
                name="all"
              />
            }
            label="All"
          />

          {checkboxOptions.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleChange}
                  name={option.id}
                />
              }
              label={
                <>
                  {option.label}
                  <a
                    href={`https://apiv2.kinsainsights.com/api/v1/${option.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton
                      className={classes.icon}
                      size="small"
                      aria-label="open endpoint link"
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </a>
                </>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </DialogCustom>
  );
};

export default AdminRoleEndpointsDialog;
