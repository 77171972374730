import React, { PureComponent, ErrorInfo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnregisterCallback } from 'history';
import * as Sentry from '@sentry/browser';

import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// For info on what this component does, see https://reactjs.org/docs/error-boundaries.html

interface State {
  hasError: boolean;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
    },
    title: {},
    body: {},
  });

class ErrorBoundary extends PureComponent<Props, State> {
  state = {
    hasError: false,
  };

  unlisten: UnregisterCallback | undefined;

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo as unknown as Record<string, unknown>);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { classes } = this.props;

    if (hasError) {
      return (
        <div className={classes.root}>
          <Typography variant="h5" align="center" className={classes.title} gutterBottom>
            Oops, something went wrong.
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary" className={classes.body}>
            Contact a Kinsa Insights administrator if this problem persists.
          </Typography>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(withRouter(ErrorBoundary));
