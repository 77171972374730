import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDown, ExitToAppRounded, AccountCircle } from '@material-ui/icons';

import { useCurrentUser } from 'hooks/context';
import { deleteAuthCookies } from 'utils/browser';
import { routes } from 'router/routes';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import logo from 'assets/images/insights-logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#fff',
      height: APP_HEADER_HEIGHT,
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      height: '100%',
    },
    left: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(0, 2),
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
    },
    image: {
      maxHeight: 45,
      marginRight: theme.spacing(2),
    },
    menuIcon: {
      fontSize: '150%',
    },
    userName: {
      marginRight: theme.spacing(1),
      textTransform: 'none',
    },
  }),
);

const AppHeaderDesktop = () => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currentUser = useCurrentUser();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    deleteAuthCookies();
    setTimeout(() => {
      history.push(routes.login.value);
    });
  }

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={3}>
      <Toolbar className={classes.toolbar}>
        <span className={classes.left}>
          <Link to={routes.root.value}>
            <img src={logo} alt="Kinsa Insights" className={classes.image} />
          </Link>
        </span>

        <Button
          data-cy="user-menu"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Typography className={classes.userName} variant="button" color="primary">
            {currentUser?.firstName ?? 'User'}
          </Typography>
          <ArrowDropDown color="primary" className={classes.menuIcon} />
        </Button>

        <Menu
          id="user-menu"
          data-testid="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClose} component={Link} to={routes.account.value}>
            <AccountCircle />
            &nbsp;My Account
          </MenuItem>
          <MenuItem onClick={handleLogout} data-cy="logout">
            <ExitToAppRounded />
            &nbsp;Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeaderDesktop;
