import { useState } from 'react';

import { OrganizationDetails, Team } from '@types';

import { BaseProps, TeamsByCategoryCollection } from '../AdminTeamsDialog/types';
import {
  AdminTeamCreateDialog,
  EditTeamsDialog,
  EditTeamDialog,
  DeleteTeamDialog,
} from '../AdminTeamsDialog/dialogs';
import { VIEW_MODE } from '../AdminTeamsDialog/dialogs/constants';

type Props = BaseProps & {
  matchedOrganization?: OrganizationDetails;
  teamsByCategory: TeamsByCategoryCollection;
  category: string;
};

const AdminTeamsEditDialog = ({
  open,
  close,
  category,
  matchedOrganization,
  teamsByCategory,
}: Props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.viewTeams);
  const [selectedTeam, setSelectedTeam] = useState<Team>();

  const resetDialog = () => {
    setSelectedTeam(undefined);
    setViewMode(VIEW_MODE.viewTeams);
    close();
  };

  const props = {
    close: resetDialog,
    category,
    open,
    matchedOrganization,
    teamsByCategory,
    setViewMode,
  };

  switch (viewMode) {
    case VIEW_MODE.createTeam:
      return <AdminTeamCreateDialog {...props} />;
    case VIEW_MODE.editTeam:
      return <EditTeamDialog {...props} team={selectedTeam} />;
    case VIEW_MODE.deleteTeam:
      return <DeleteTeamDialog {...props} team={selectedTeam} />;
    case VIEW_MODE.viewTeams:
    default:
      return <EditTeamsDialog {...props} setSelectedTeam={setSelectedTeam} />;
  }
};

export default AdminTeamsEditDialog;
