import _ from 'lodash';

// Recursively call function on object keys
export function deepMapKeys<T>(
  originalObject: any,
  callback: (key: string) => string = _.camelCase,
): T {
  if (!_.isPlainObject(originalObject)) {
    return originalObject;
  }

  return Object.keys(originalObject || {}).reduce((newObject, key) => {
    const newKey = callback(key);
    const originalValue = originalObject[key];
    let newValue = originalValue;

    if (Array.isArray(originalValue)) {
      newValue = originalValue.map(item => deepMapKeys(item, callback));
    } else if (typeof originalValue === 'object') {
      newValue = deepMapKeys(originalValue, callback);
    }

    return {
      ...newObject,
      [newKey]: newValue,
    };
  }, {}) as T;
}

export function getUtcDate(date: Date) {
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
  );
}
