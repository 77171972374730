import React, { useState, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ExpandableList, CheckboxDialog } from 'components';
import { RoleDetails, RoleEndpoint } from '@types';
import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';

type Props = {
  dividerEl: React.ReactNode;
  roles: RoleDetails[];
};

type EndpointWithRoles = RoleEndpoint & { roles: RoleDetails[] };
type EndpointWithRolesCollection = Record<string, EndpointWithRoles>;

const AdminEndpointsByEndpoint = ({ dividerEl, roles }: Props) => {
  const queryClient = useQueryClient();
  const [editedEndpoint, setEditedEndpoint] = useState<EndpointWithRoles | null>(null);

  // Used for checkboxes
  const roleOptions = useMemo(
    () =>
      roles
        .map(role => ({
          id: role.roleId?.toString() || '',
          label: role.displayName || role.name,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    [roles],
  );

  const { data: endpoints = [] } = useQuery(CACHE_KEYS.endpoints, adminAPI.getAllEndpoints);
  const { mutateAsync } = useMutation(adminAPI.bulkAssignRolesToEndpoints, {
    // Returned data doesn't tell us which roles have been removed from updated endpoint, only added, so refetch all
    onSuccess: () => queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed),
  });

  // For each endpoint, place associated roles in "roles" array
  const endpointsWithRoles = useMemo(() => {
    if (!endpoints.length) {
      return [];
    }

    // Create object with endpoint ID key to make lookup in next step simpler
    const endpointsById = endpoints.reduce((acc, endpoint) => {
      acc[endpoint.endpointId] = {
        ...endpoint,
        roles: [],
      };
      return acc;
    }, {} as EndpointWithRolesCollection);

    // Associate role's endpoints with all endpoints
    roles.forEach(role => {
      role.endpointsList.forEach(roleEndpoint => {
        endpointsById[roleEndpoint.endpointId]?.roles.push(role);
      });
    });

    return Object.values(endpointsById).sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }, [endpoints, roles]);

  const defaultSelectedOptions = useMemo(
    () => editedEndpoint?.roles.map(r => r.roleId?.toString() ?? ''),
    [editedEndpoint],
  );

  const handleSubmit = async (selectedOptions: string[]) => {
    if (editedEndpoint) {
      await mutateAsync({
        endpointId: editedEndpoint.endpointId,
        roleIds: selectedOptions,
      });
    }
  };

  return (
    <>
      {endpointsWithRoles.map(endpoint => (
        <React.Fragment key={endpoint.endpointId}>
          <ExpandableList
            title={`${endpoint.name} (${endpoint.roles.length})`}
            listItems={endpoint.roles.map(r => r.displayName ?? r.name)}
            buttonText="Select Organizations"
            handleClickButton={() => setEditedEndpoint(endpoint)}
          />
          {dividerEl}
        </React.Fragment>
      ))}

      <CheckboxDialog
        open={Boolean(editedEndpoint)}
        onClose={() => setEditedEndpoint(null)}
        onSubmit={handleSubmit}
        options={roleOptions}
        title={`Select Organizations linked to '${editedEndpoint?.name}'`}
        defaultSelectedOptions={defaultSelectedOptions}
        appendCountToTitle
        displayAllToggle
      />
    </>
  );
};

export default AdminEndpointsByEndpoint;
