import React, { memo } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AppHeaderDesktop, AppHeaderMobile, ErrorBoundary } from '../';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import { routes } from 'router/routes';

type Props = {
  children: React.ReactNode;
  pathname: string;
};

const FULL_WIDTH_ROUTES: string[] = [routes.root.value];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    body: {
      height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
      margin: '0 auto',
    },
    bodyMaxWidth: {
      maxWidth: theme.breakpoints.values.lg,
    },
  }),
);

const App = ({ children, pathname }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const bodyClasses = [classes.body];

  if (!FULL_WIDTH_ROUTES.includes(pathname)) {
    bodyClasses.push(classes.bodyMaxWidth);
  }

  return (
    <div className={classes.root} data-cy="app" data-testid="app">
      {isMobile ? <AppHeaderMobile /> : <AppHeaderDesktop />}
      <main className={bodyClasses.join(' ')}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
};

// Good idea to wrap Context Provider's immediate child in memo() to prevent unnecessary re-renders
// See: https://twitter.com/sophiebits/status/1228942768543686656
export default memo(App);
