import React, { useState } from 'react';
import { AppBar, Toolbar, ListItem, Typography, Drawer, IconButton } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link, NavLink } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ExitToAppRounded, AccountCircle, MenuRounded, CloseRounded } from '@material-ui/icons';

import { useCurrentUser } from 'hooks/context';
import { deleteAuthCookies } from 'utils/browser';
import { routes } from 'router/routes';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import logo from 'assets/images/insights-logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#fff',
      height: APP_HEADER_HEIGHT,
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: '1 1 0',
    },
    image: {
      maxHeight: 35,
    },
    toggleBtn: {
      padding: theme.spacing(1),
    },
    drawer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 240,
    },
    drawerTop: {
      display: 'flex',
      flexDirection: 'column',
    },
    closeBtn: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      alignSelf: 'flex-end',
    },
    drawerUser: {
      margin: theme.spacing(0, 0, 1),
    },
    userName: {
      padding: theme.spacing(0, 2, 1),
    },
    accountLink: {
      color: theme.palette.text.primary,
    },
    navLink: {
      color: theme.palette.text.primary,
      padding: theme.spacing(2.5, 2),
    },
    navLinkActive: {
      fontWeight: 500,
    },
  }),
);

const AppHeaderMobile = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const currentUser = useCurrentUser();

  function handleLogout() {
    deleteAuthCookies();
    setTimeout(() => {
      history.push(routes.login.value);
    });
  }

  function toggleDrawer() {
    setDrawerOpen(state => !state);
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar} elevation={3}>
        <Toolbar className={classes.toolbar}>
          <Link to={routes.root.value}>
            <img src={logo} alt="Kinsa Insights" className={classes.image} />
          </Link>
          <IconButton
            key="toggle"
            aria-label="Toggle menu"
            className={classes.toggleBtn}
            onClick={toggleDrawer}
          >
            <MenuRounded fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paperAnchorRight: classes.drawer }}
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <div className={classes.drawerTop}>
          <IconButton
            key="toggle"
            aria-label="Toggle menu"
            className={classes.closeBtn}
            onClick={toggleDrawer}
          >
            <CloseRounded fontSize="large" />
          </IconButton>
          <div className={classes.drawerUser}>
            <Typography className={classes.userName} variant="h3">
              {currentUser ? currentUser.firstName : 'User'}
            </Typography>
            <ListItem
              className={classes.accountLink}
              activeClassName={classes.navLinkActive}
              alignItems="center"
              component={NavLink}
              to={routes.account.value}
            >
              <AccountCircle />
              <span>&nbsp;My Account</span>
            </ListItem>
          </div>
        </div>
        <div>
          <ListItem className={classes.navLink} alignItems="center" button onClick={handleLogout}>
            <ExitToAppRounded />
            <span>&nbsp;Logout</span>
          </ListItem>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(AppHeaderMobile);
