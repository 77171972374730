import { useEffect, useState } from 'react';
import { Formik, FormikHelpers, FormikProps, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Cookie from 'js-cookie';
import _ from 'lodash';
import { useQueryClient } from 'react-query';

import { TextInput, CheckboxInput } from '../Formik';
import { FullscreenFormContainer, RouterLink, ErrorText } from '../';
import { routes } from 'router/routes';
import { setDocumentTitle, setAuthCookies, deleteAuthCookies } from 'utils/browser';
import { ADMIN_ROLE_NAME, COOKIE_NAMES } from 'utils/constants';
import { authAPI } from 'api';

type FormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxWrapper: {
      margin: theme.spacing(2, 0, 4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    checkbox: {
      marginLeft: theme.spacing(-1.5),
    },
    contactLink: {
      marginTop: theme.spacing(2),
    },
  }),
);

// Form validation schema
const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().email('Valid email required').required('Required'),
});

const Login = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    // If a user already has a refresh token, boot them to app to try to get authenticated
    // Worst case scenario they come right back here w/o a token
    const refreshToken = Cookie.get(COOKIE_NAMES.refreshToken);
    if (refreshToken) {
      history.push(routes.root.value);
      return;
    }

    setDocumentTitle(routes.login.label);
  }, [history]);

  return (
    <FullscreenFormContainer>
      <div className={classes.root}>
        <Typography variant="h4">Welcome to Kinsa Insights Admin Portal</Typography>

        <Formik<FormValues>
          initialValues={{
            email: Cookie.get(COOKIE_NAMES.email) || '',
            password: '',
            rememberMe: !!Cookie.get(COOKIE_NAMES.email),
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            try {
              setSubmitting(true);
              const tokenSet = await authAPI.login(values.email, values.password);
              setAuthCookies(tokenSet);
              const user = await authAPI.getUserInfo();
              const isAdminUser = user.roleName === ADMIN_ROLE_NAME;
              if (!isAdminUser) {
                deleteAuthCookies();
                setServerError(
                  'You are not authorized to access this platform.\nPlease contact your system administrator.',
                );
                return;
              }

              if (values.rememberMe) {
                Cookie.set(COOKIE_NAMES.email, values.email);
              } else {
                Cookie.remove(COOKIE_NAMES.email);
              }

              setServerError(null);
              queryClient.clear();
              history.push(routes.root.value);
            } catch (err) {
              const errorResp = err.response ? await err.response.json() : null;
              const errorMsg = _.get(errorResp, 'errors[0].message', 'Error logging in.');
              setServerError(errorMsg);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }: FormikProps<FormValues>) => (
            <Form noValidate className={classes.form}>
              <Field
                required
                id="email"
                name="email"
                type="email"
                label="Email"
                margin="normal"
                component={TextInput}
              />
              <Field
                required
                id="password"
                name="password"
                type="password"
                label="Password"
                margin="normal"
                component={TextInput}
              />
              <div className={classes.checkboxWrapper}>
                <Field
                  className={classes.checkbox}
                  id="user-remember"
                  name="rememberMe"
                  label="Remember me"
                  margin="normal"
                  component={CheckboxInput}
                />
                <Link
                  variant="body2"
                  align="center"
                  color="primary"
                  component={RouterLink}
                  to={routes.forgotPassword.value}
                >
                  Forgot your password?
                </Link>
              </div>

              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                Login
              </Button>

              <Typography align="center" variant="body2" className={classes.contactLink}>
                Don’t have an account? Reach out to us at&nbsp;
                <Link
                  href="mailto:customer@kinsahealth.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  customer@kinsahealth.com
                </Link>
              </Typography>

              <ErrorText text={serverError} />
            </Form>
          )}
        </Formik>
      </div>
    </FullscreenFormContainer>
  );
};

export default withRouter(Login);
