import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { APP_HEADER_HEIGHT } from 'utils/constants';
import { useCurrentUser } from 'hooks/context';
import { Card, UpdatePassword, UserAccountInfo } from '../';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: APP_HEADER_HEIGHT,
      padding: theme.spacing(5, 4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 2),
      },
    },
    pageTitle: {
      marginBottom: theme.spacing(4),
    },
    body: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'stretch',
      },
    },
    card: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const UserAccountDashboard = () => {
  const classes = useStyles();
  const currentUser = useCurrentUser();

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="textSecondary" className={classes.pageTitle}>
        Account
      </Typography>

      <div className={classes.body}>
        <Card title="Account Info" className={classes.card} paddingTop={1}>
          <UserAccountInfo user={currentUser} />
        </Card>
        <Card title="Update Password" className={classes.card} paddingTop={1}>
          <UpdatePassword />
        </Card>
      </div>
    </div>
  );
};

export default UserAccountDashboard;
