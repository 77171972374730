import { useEffect, useMemo } from 'react';
import { Button, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { RoleDetails, SignalDetails } from '@types';
import { useCheckboxes } from 'hooks/forms';
import { usePatchRole } from 'hooks/roles';
import { DialogCustom } from '..';

type Props = {
  selectedRole: RoleDetails;
  open: boolean;
  close: () => void;
  className: string;
  validSignals: SignalDetails[];
};

const AdminSignalsDialog = ({ className, selectedRole, open, close, validSignals }: Props) => {
  const checkboxOptions = useMemo(
    () =>
      validSignals.map(signal => ({
        id: signal.signalId.toString(),
        label: signal.apiName,
      })),
    [validSignals],
  );

  const {
    isAllChecked,
    isIndeterminateChecked,
    selectedOptions,
    setSelectedOptions,
    handleChange,
    handleChangeAll,
  } = useCheckboxes(checkboxOptions);

  const patchRole = usePatchRole();

  useEffect(() => {
    if (open) {
      // Reset when opening dialog
      setSelectedOptions(selectedRole.signalsList.map(signal => signal.signalId.toString()));
    }
  }, [open, setSelectedOptions, selectedRole]);

  const handleSubmit = async () => {
    try {
      if (selectedRole.roleId) {
        await patchRole.mutateAsync({
          role_id: selectedRole.roleId,
          signals: selectedOptions.map(Number),
        });
        close();
      }
    } catch (err) {
      console.warn('Error updating role with signals', err);
    }
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      open={open}
      onClose={close}
      title={`Select Signals (${selectedOptions.length}/${checkboxOptions.length})`}
      label="select signals"
      actions={
        <>
          <Button onClick={close} color="primary" disabled={patchRole.isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={patchRole.isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl component="fieldset" data-testid="signals-dialog">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={isIndeterminateChecked}
                checked={isAllChecked}
                onChange={handleChangeAll}
                name="all"
              />
            }
            label="All"
          />

          {checkboxOptions.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleChange}
                  name={option.id}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </DialogCustom>
  );
};

export default AdminSignalsDialog;
