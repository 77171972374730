import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { adminAPI } from 'api';
import { Team } from '@types';
import { DialogCustom, ErrorDialog } from 'components';
import { CACHE_KEYS } from 'utils/constants';

import { VIEW_MODE } from './constants';
import { SharedProps } from '../types';

type Props = SharedProps & {
  team?: Team;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      background: theme.palette.error.main,
      color: 'white',
    },
  }),
);

const DeleteTeamDialog = ({ close, open, setViewMode, team }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);

  const handleClose = () => setViewMode(VIEW_MODE.viewTeams);

  const { mutate: deleteTeam, isLoading } = useMutation(adminAPI.deleteTeam, {
    onSuccess: (_, { teamId }) => {
      // Update cache after successful delete
      const teams = queryClient.getQueryData<Team[]>(CACHE_KEYS.teams) || [];
      const updatedTeams = teams?.filter(team => team.teamId !== teamId);
      queryClient.setQueryData(CACHE_KEYS.teams, updatedTeams);
      handleClose();
    },
    onError: (e: Error) => setError(e),
  });

  const handleSubmit = () => {
    if (team?.teamId) {
      deleteTeam({ teamId: team.teamId });
    }
  };

  return error ? (
    <ErrorDialog
      open={open}
      close={close}
      onActionClick={() => {
        setError(null);
        setViewMode(VIEW_MODE.viewTeams);
      }}
    />
  ) : (
    <DialogCustom
      label="delete team"
      onClose={close}
      open={open}
      title={`Delete ${team?.name} team?`}
      actions={
        <>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={classes.action}
            disabled={isLoading}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        This will delete the team for all organizations associated with it and set the dashboard
        views back to defaults for all users within this team.
      </Typography>
    </DialogCustom>
  );
};

export default DeleteTeamDialog;
