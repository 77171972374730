import React from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';

import { CACHE_KEYS, CATEGORIES } from 'utils/constants';
import { adminAPI } from 'api';
import { RoleDetails, AdminSidebarState } from '@types';
import { DialogCustom } from '..';

type Props = {
  open: boolean;
  close: () => void;
  setSelectedSidebarOption: (state: AdminSidebarState) => void;
};

const AdminAddRoleDialog = ({ close, open, setSelectedSidebarOption }: Props) => {
  const [orgName, setOrgName] = React.useState('');
  const [orgCategory, setOrgCategory] = React.useState('');
  const queryClient = useQueryClient();

  const clearForm = () => {
    setOrgName('');
    setOrgCategory('');
  };

  const { mutate: createRole, isLoading } = useMutation(adminAPI.postRole, {
    onSuccess: newRole => {
      clearForm();
      const roles = queryClient.getQueryData<RoleDetails[]>(CACHE_KEYS.rolesDetailed) || [];
      const updatedRoles = [...roles, newRole];

      queryClient.invalidateQueries(CACHE_KEYS.organizationsDetailed);

      queryClient.setQueryData(CACHE_KEYS.rolesDetailed, updatedRoles);
      setSelectedSidebarOption({ roleId: newRole.roleId, type: 'role' });
    },
  });

  const handleSubmit = () => {
    createRole({
      name: orgName,
      display_name: orgName,
      enabled: true,
      category: orgCategory,
    });
    close();
  };

  const handleCancel = () => {
    clearForm();
    close();
  };

  const isFormValid = orgName && orgCategory;

  return (
    <DialogCustom
      open={open}
      onClose={close}
      title="Create an organization"
      label="create-org"
      actions={
        <>
          <Button onClick={handleCancel} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isFormValid || isLoading}
          >
            Create Organization
          </Button>
        </>
      }
    >
      <TextField
        autoFocus
        required
        variant="outlined"
        fullWidth
        type="text"
        label="Organization name"
        margin="normal"
        value={orgName}
        onChange={e => setOrgName(e.target.value.trimStart())}
      />

      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="org-category">Category</InputLabel>
        <Select
          required
          labelId="org-category"
          id="org-category"
          value={orgCategory}
          onChange={e => setOrgCategory(e.target.value as string)}
          label="Category"
        >
          {Object.values(CATEGORIES).map(cat => (
            <MenuItem key={cat} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DialogCustom>
  );
};

export default AdminAddRoleDialog;
