import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Fab, ListItem, Divider } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import _ from 'lodash';

import { RoleDetails, AdminSidebarState } from '@types';
import { AdminAddRoleDialog } from '../';

type Props = {
  roles: RoleDetails[];
  isAllUsersView: boolean;
  selectedSidebarOption: AdminSidebarState;
  setSelectedSidebarOption: (state: AdminSidebarState) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0),
      borderRight: `1px solid ${theme.palette.kinsaGrey200}`,
    },
    gutter: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontWeight: 700,
    },
    fab: {
      flex: 'none',
    },
    rolesList: {
      marginTop: theme.spacing(4.5),
    },
    roleName: {
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listItem: {
      padding: theme.spacing(1.5, 3, 1.5, 5),
    },
    listItemStandalone: {
      paddingLeft: theme.spacing(3),
    },
    categorySection: {
      marginBottom: theme.spacing(4),
    },
    categoryLabel: {
      margin: theme.spacing(1, 0),
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    divider: {
      margin: theme.spacing(4, 0),
    },
  }),
);

const AdminSidebar = ({ roles, selectedSidebarOption, setSelectedSidebarOption }: Props) => {
  const classes = useStyles();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);

  // Note: "null" id/category denote all users
  const rolesByCategory = _.groupBy(roles, 'category');
  const { null: allUsersGroup, ...groupedRoles } = rolesByCategory;
  const allUsersRole = allUsersGroup?.[0];

  return (
    <div className={classes.root} data-cy="admin-sidebar">
      <div className={`${classes.row} ${classes.gutter}`}>
        <Typography variant="h6" className={classes.title}>
          Organizations
        </Typography>
        <Fab
          className={classes.fab}
          color="primary"
          size="medium"
          aria-label="add org"
          onClick={e => setShowCreateDialog(true)}
        >
          <AddIcon />
        </Fab>
      </div>

      {/* Regular Orgs */}
      <div className={classes.rolesList}>
        {_.map(groupedRoles, (roles, category) => {
          const rows = roles.map(role => (
            <ListItem
              key={role.roleId}
              className={`${classes.row} ${classes.listItem}`}
              onClick={() => {
                window.scrollTo({ top: 0 });
                setSelectedSidebarOption({ roleId: role.roleId, type: 'role' });
              }}
              selected={
                selectedSidebarOption.type === 'role' &&
                selectedSidebarOption.roleId === role.roleId
              }
              button
            >
              <Typography className={classes.roleName}>{role.displayName || role.name}</Typography>
              <Typography>{role.userCount ?? 0}</Typography>
            </ListItem>
          ));

          return (
            <div key={category} className={classes.categorySection}>
              <Typography
                variant="caption"
                display="block"
                color="textSecondary"
                className={`${classes.categoryLabel} ${classes.gutter}`}
              >
                {category}
              </Typography>
              {rows}
            </div>
          );
        })}

        <Divider className={`${classes.divider} ${classes.gutter}`} variant="middle" />

        {/* All Users "org" */}
        {allUsersRole && (
          <ListItem
            button
            className={`${classes.row} ${classes.listItem} ${classes.listItemStandalone}`}
            selected={selectedSidebarOption.type === 'role' && !selectedSidebarOption.roleId}
            onClick={() => setSelectedSidebarOption({ roleId: null, type: 'role' })}
          >
            <Typography>{allUsersRole.displayName}</Typography>
            <Typography>{allUsersRole.userCount}</Typography>
          </ListItem>
        )}
        <Divider className={`${classes.divider} ${classes.gutter}`} variant="middle" />

        <ListItem
          button
          className={`${classes.row} ${classes.listItem} ${classes.listItemStandalone}`}
          selected={selectedSidebarOption.type === 'domains'}
          onClick={() => setSelectedSidebarOption({ roleId: null, type: 'domains' })}
        >
          <Typography>Data Sources</Typography>
        </ListItem>

        <ListItem
          button
          className={`${classes.row} ${classes.listItem} ${classes.listItemStandalone}`}
          selected={selectedSidebarOption.type === 'endpoints'}
          onClick={() => setSelectedSidebarOption({ roleId: null, type: 'endpoints' })}
        >
          <Typography>Endpoints</Typography>
        </ListItem>

        <ListItem
          button
          className={`${classes.row} ${classes.listItem} ${classes.listItemStandalone}`}
          selected={selectedSidebarOption.type === 'teams'}
          onClick={() => setSelectedSidebarOption({ roleId: null, type: 'teams' })}
        >
          <Typography>Teams</Typography>
        </ListItem>
      </div>

      <AdminAddRoleDialog
        open={showCreateDialog}
        close={() => setShowCreateDialog(false)}
        setSelectedSidebarOption={setSelectedSidebarOption}
      />
    </div>
  );
};

export default AdminSidebar;
