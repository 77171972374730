import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';
import { RoleDetails } from '@types';

export const useAllRoles = (filterAllUsersOption = false) => {
  return useQuery(CACHE_KEYS.rolesDetailed, adminAPI.getAllRoles, {
    select: roles => {
      if (filterAllUsersOption) {
        // Filter out "All Users" "role" and then sort
        return roles
          .filter(role => role.roleId)
          .sort((a, b) => {
            const sortNameA = (a.displayName || a.name).toLowerCase();
            const sortNameB = (b.displayName || b.name).toLowerCase();
            return sortNameA.localeCompare(sortNameB);
          });
      }

      return roles;
    },
  });
};

export const usePatchRole = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();

  const patchRole = useMutation(adminAPI.patchRole, {
    onSuccess: updatedRole => {
      // Update cache after successful update
      const roles = queryClient.getQueryData<RoleDetails[]>(CACHE_KEYS.rolesDetailed);
      const updatedRoles = roles?.map(existingRole =>
        existingRole.roleId === updatedRole.roleId
          ? { ...existingRole, ...updatedRole }
          : existingRole,
      );
      queryClient.setQueryData(CACHE_KEYS.rolesDetailed, updatedRoles);
      onSuccessCallback?.();
    },
  });

  return patchRole;
};
