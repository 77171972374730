import { useMutation, useQueryClient } from 'react-query';
import { FormikValues, Field } from 'formik';
import * as Yup from 'yup';

import { Button, Typography } from '@material-ui/core';

import { FormikDialog, TextInput } from 'components/Formik';
import { adminAPI } from 'api';
import { RoleUser } from '@types';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  close: () => void;
  open: boolean;
  organizationId?: number; // if in CREATE mode
  user?: RoleUser; // if in EDIT mode
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const DEFAULT_FORM_VALUES: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Valid email required').required('Required'),
});

const AdminSingleUserDialog = ({ close, open, organizationId, user }: Props) => {
  const queryClient = useQueryClient();

  const postUserMutation = useMutation(adminAPI.postUser, {
    onSuccess: newUser => {
      // Update cache after successful update
      const allUsers = queryClient.getQueryData<RoleUser[]>(CACHE_KEYS.users) || [];
      queryClient.setQueryData(CACHE_KEYS.users, [newUser, ...allUsers]);
      // Refetch user-count because per-role counts will have changed
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);

      close();
    },
  });

  const patchUserMutation = useMutation(adminAPI.patchUser, {
    onSuccess: (updatedUser, vars) => {
      // Update cache after successful update
      const allUsers = queryClient.getQueryData<RoleUser[]>(CACHE_KEYS.users) || [];
      const updatedUsers = allUsers.map(user => {
        return user.id === updatedUser.id ? { ...user, ...updatedUser } : user;
      });
      queryClient.setQueryData(CACHE_KEYS.users, updatedUsers);

      // Refetch user-count if removed user from role
      if (!vars.organization_id) {
        queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
      }

      close();
    },
  });

  const isEditMode = Boolean(user);

  const onSubmit = ({ firstName, lastName, email }: FormikValues) => {
    if (isEditMode && user) {
      patchUserMutation.mutate({
        id: user.id,
        first_name: firstName,
        last_name: lastName,
        email,
      });
    } else {
      postUserMutation.mutate({
        first_name: firstName,
        last_name: lastName,
        email: email,
        organization_id: organizationId,
      });
    }
  };

  const handleCancel = () => {
    close();
    postUserMutation.reset();
    patchUserMutation.reset();
  };

  const formikConfig = {
    validationSchema,
    initialValues: user
      ? {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }
      : DEFAULT_FORM_VALUES,
    onSubmit,
  };

  return (
    <FormikDialog
      maxWidth="xs"
      formikConfig={formikConfig}
      label="add-user-dialog"
      onClose={close}
      open={open}
      title={`${isEditMode ? 'Edit' : 'Add new'} user`}
      actions={
        <>
          <Button
            color="primary"
            disabled={postUserMutation.isLoading || patchUserMutation.isLoading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={postUserMutation.isLoading || patchUserMutation.isLoading}
            type="submit"
            variant="contained"
          >
            {isEditMode ? 'Save Changes' : 'Add User & Send Invite'}
          </Button>
        </>
      }
    >
      <Field
        required
        fullWidth
        id="firstName"
        name="firstName"
        type="text"
        label="First name"
        margin="normal"
        variant="outlined"
        component={TextInput}
      />
      <Field
        required
        fullWidth
        id="lastName"
        name="lastName"
        type="text"
        label="Last name"
        margin="normal"
        variant="outlined"
        component={TextInput}
      />
      <Field
        required
        fullWidth
        id="email"
        name="email"
        type="email"
        label="Email"
        margin="normal"
        variant="outlined"
        component={TextInput}
      />
      {postUserMutation.error instanceof Error && (
        <Typography align="center" color="error">
          {postUserMutation.error.message || 'Error creating user.'}
        </Typography>
      )}

      {patchUserMutation.error instanceof Error && (
        <Typography align="center" color="error">
          {patchUserMutation.error.message || 'Error updating user.'}
        </Typography>
      )}
    </FormikDialog>
  );
};

export default AdminSingleUserDialog;
