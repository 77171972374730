import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FormikValues, Field } from 'formik';
import * as Yup from 'yup';

import { Button } from '@material-ui/core';

import { adminAPI } from 'api';
import { Team } from '@types';
import { CACHE_KEYS } from 'utils/constants';
import { ErrorDialog } from 'components';
import { FormikDialog, TextInput } from 'components/Formik';

import { VIEW_MODE } from './constants';
import { ErrorContent, SharedProps } from '../types';

type Props = SharedProps & {
  category: string;
  newlyCreatedTeams?: Team[];
  setNewlyCreatedTeams?: React.Dispatch<React.SetStateAction<Team[]>>;
};

const validationSchema = Yup.object().shape({
  teamName: Yup.string().required('Required').max(255, 'Limit 255 characters').trim(),
  teamDescription: Yup.string().required('Required').max(255, 'Limit 255 characters').trim(),
});

const AdminTeamCreateDialog = ({
  close,
  open,
  category,
  setViewMode,
  newlyCreatedTeams,
  setNewlyCreatedTeams,
}: Props) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<ErrorContent | null>(null);

  const { mutate: createTeam, isLoading } = useMutation(adminAPI.postTeam, {
    onSuccess: newTeam => {
      const teams = queryClient.getQueryData<Team[]>(CACHE_KEYS.teams) || [];
      const updatedTeams = [...teams, newTeam];

      const newTeams = [...(newlyCreatedTeams || []), newTeam];
      setNewlyCreatedTeams?.(newTeams);

      queryClient.setQueryData(CACHE_KEYS.teams, updatedTeams);
      setViewMode(VIEW_MODE.viewTeams);
    },
    onError: (e: Error, { category, name }) => {
      if (e.message === `A Team already exists in the ${category} category with name ${name}.`) {
        setError({
          title: 'Team already exists',
          actionText: 'Got it',
          errorMessage:
            'A team with this name already exists in this vertical. Please enter a different name.',
        });
      } else {
        setError({
          title: 'Ooops! Something went wrong',
          actionText: 'Ok',
          errorMessage:
            'Sorry, we had some technical problems with your last operation. Please try again in a few minutes',
        });
      }
    },
  });

  const handleClose = () => setViewMode(VIEW_MODE.viewTeams);

  const formikConfig = {
    validationSchema,
    initialValues: {
      teamName: '',
      teamDescription: '',
    },
    onSubmit: (values: FormikValues) =>
      createTeam({
        category,
        description: values.teamDescription,
        name: values.teamName,
      }),
  };

  return error ? (
    <ErrorDialog {...error} open={open} close={close} onActionClick={() => setError(null)} />
  ) : (
    <FormikDialog
      formikConfig={formikConfig}
      label="create team"
      onClose={close}
      open={open}
      title="Create team"
      actions={
        <>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" disabled={isLoading} type="submit" variant="contained">
            Add team
          </Button>
        </>
      }
    >
      <Field
        required
        fullWidth
        id="teamName"
        name="teamName"
        type="text"
        label="Team name"
        margin="normal"
        variant="outlined"
        component={TextInput}
      />
      <Field
        required
        fullWidth
        id="teamDescription"
        name="teamDescription"
        type="text"
        label="Description"
        margin="normal"
        variant="outlined"
        component={TextInput}
      />
    </FormikDialog>
  );
};

export default AdminTeamCreateDialog;
