import { responsiveFontSizes } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createTheme';

const BTN_PADDING_BASE = 10;

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: '#00758F',
      light: '#00A1C5',
      dark: '#004961',
    },
    secondary: {
      main: '#587880',
      light: '#CAD8DD',
      dark: '#1E4047',
    },
    error: {
      main: '#A00008',
    },
    text: {
      primary: '#212121',
      secondary: '#6D6D6D',
      disabled: '#ABABAB',
    },
    background: {
      default: '#F7F9FA',
      paper: '#FFFFFF',
    },
    divider: '#BDC7C9',
    kinsaBlue100: '#E0EFF3',
    kinsaGrey100: '#EDF3F5',
    kinsaGrey200: '#E7E7E7',
    kinsaGrey400: '#AFC4C9',
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '0.75rem',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#212121',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$disabled': {
          opacity: 1,
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: `1px solid #F7F9FA`,
        '&:hover': {
          backgroundColor: '#E0EFF3',
        },
      },
    },
    MuiPaper: {
      elevation3: {
        boxShadow: `
          0 3px 3px -2px rgba(0,0,0,0.2),
          0 3px 4px 0 rgba(0,0,0,0.01),
          0 1px 8px 0 rgba(0,0,0,0.05)
        `,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        paddingTop: BTN_PADDING_BASE,
        paddingBottom: BTN_PADDING_BASE,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.5,
        lineHeight: 1.15,
      },
      outlined: {
        paddingTop: BTN_PADDING_BASE,
        paddingBottom: BTN_PADDING_BASE,
        paddingLeft: 16,
        paddingRight: 16,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&$disabled': {
          color: '#fff',
          backgroundColor: '#BADBE3',
        },
      },
    },
    MuiCard: {
      root: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#BDC7C9',
        borderRadius: 6,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#BDC7C9',
      },
    },
    MuiRadio: {
      root: {
        color: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'black',
        fontSize: '0.9rem',
      },
    },
  },
});

/*
TYPOGRAPHY
*/

// Values based on: https://www.figma.com/file/ceu0uA5kgcEmW3mF7vEpJa/Insights-Explorer-Library?node-id=7%3A2

// h1
theme.typography.h1.fontSize = '36px';
theme.typography.h1.fontWeight = 300;
theme.typography.h1.lineHeight = 1.5;
theme.typography.h1.letterSpacing = -0.5;

// h2
theme.typography.h2.fontSize = '26px';
theme.typography.h2.fontWeight = 300;
theme.typography.h2.lineHeight = 1.5;
theme.typography.h2.letterSpacing = 0;

// h3
theme.typography.h3.fontSize = '22px';
theme.typography.h3.fontWeight = 400;
theme.typography.h3.lineHeight = 1.8;
theme.typography.h3.letterSpacing = 0;

// h4
theme.typography.h4.fontSize = '19px';
theme.typography.h4.fontWeight = 400;
theme.typography.h4.lineHeight = 1.8;
theme.typography.h4.letterSpacing = 0.25;

// subtitle 1
theme.typography.subtitle1.fontSize = '16px';
theme.typography.subtitle1.fontWeight = 400;
theme.typography.subtitle1.lineHeight = 1.3;
theme.typography.subtitle1.letterSpacing = 0.15;

// subtitle 2
theme.typography.subtitle2.fontSize = '14px';
theme.typography.subtitle2.fontWeight = 500;
theme.typography.subtitle2.lineHeight = 1.3;
theme.typography.subtitle2.letterSpacing = 0.1;

// body 1
theme.typography.body1.fontSize = '16px';
theme.typography.body1.fontWeight = 400;
theme.typography.body1.lineHeight = 1.8;
theme.typography.body1.letterSpacing = 0.5;

// body 2
theme.typography.body2.fontSize = '14px';
theme.typography.body2.fontWeight = 400;
theme.typography.body2.lineHeight = 1.8;
theme.typography.body2.letterSpacing = 0.25;

// caption
theme.typography.caption.fontSize = '12px';
theme.typography.caption.fontWeight = 400;
theme.typography.caption.lineHeight = 1.3;
theme.typography.caption.letterSpacing = 0.4;

// button
theme.typography.button.fontSize = '14px';
theme.typography.button.fontWeight = 500;
theme.typography.button.lineHeight = 1.15;
theme.typography.button.letterSpacing = 0.5;

// overline
theme.typography.overline.fontSize = '11px';
theme.typography.overline.fontWeight = 400;
theme.typography.overline.lineHeight = 1.6;
theme.typography.overline.letterSpacing = 1.5;
theme.typography.overline.textTransform = 'uppercase';

const responsiveFontOptions = {
  factor: 2, // default: 2
};

// export default theme;
export default responsiveFontSizes(theme, responsiveFontOptions);
