import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { isValid, parseISO } from 'date-fns';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonBase, InputAdornment, Slider, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';

import { RoleDetails } from '@types';

import { adminAPI } from 'api';
import { CACHE_KEYS } from 'utils/constants';
import { usePatchRole } from 'hooks/roles';
import { useAllOrganizations } from 'hooks/organizations';
import {
  AdminRoleDomainsDialog,
  AdminRoleEndpointsDialog,
  AdminRoleGeo,
  AdminSignalsDialog,
  AdminTeamsDialog,
} from '../';

type Props = {
  selectedRole: RoleDetails;
};

type DialogTypes = 'domains' | 'geo' | 'endpoints' | 'teams' | 'signals' | null;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.kinsaGrey200}`,
      borderRadius: 5,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    column: {
      borderRight: `1px solid ${theme.palette.kinsaGrey200}`,
      padding: theme.spacing(4),
      '&:last-child': {
        borderRight: 'none',
      },
    },
    dialogContainer: {
      minWidth: 380,
    },
    label: {
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    root: {
      marginTop: theme.spacing(5),
    },
    section: {
      marginBottom: theme.spacing(9),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    sectionStartDate: {
      marginBottom: theme.spacing(6.8),
    },
    sectionContents: {
      maxWidth: 188,
    },
    textButton: {
      ...theme.typography.body1,
      color: theme.palette.primary.main,
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(1.5),
      textTransform: 'uppercase',
    },
  }),
);

const AdminRolePermissions = ({ selectedRole }: Props) => {
  const classes = useStyles();
  const [forecastLength, setForecastLength] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [dialogOpen, setDialogOpen] = useState<DialogTypes>(null);
  const oldSelectedRoleId = useRef<number | null>(null);

  const patchRole = usePatchRole();
  const { data: allEndpoints = [] } = useQuery(CACHE_KEYS.endpoints, adminAPI.getAllEndpoints);
  const { data: allTeamsData = [] } = useQuery(CACHE_KEYS.teams, adminAPI.getAllTeams);
  const { data: allSignals = [] } = useQuery(CACHE_KEYS.signals, adminAPI.getAllSignals);
  const { data: allOrganizations = [] } = useAllOrganizations();

  const { data: allToucanApps = [] } = useQuery(
    CACHE_KEYS.toucanDomains,
    adminAPI.getAllToucanDomains,
  );

  const teamsByVertical = allTeamsData.filter(({ category }) => category === selectedRole.category);
  const matchedOrganization = allOrganizations.find(({ roleId }) => roleId === selectedRole.roleId);

  // Reset date and forecast form values when changing selected role
  useEffect(() => {
    if (oldSelectedRoleId.current !== selectedRole.roleId) {
      const weeksInFuture = (selectedRole.daysInFuture ?? 0) / 7;
      setForecastLength(Math.round(weeksInFuture));
      setStartDate(parseISO(selectedRole.startDateIsoformat));
      oldSelectedRoleId.current = selectedRole.roleId;
    }
  }, [selectedRole]);

  const selectedSourceCount = useMemo(
    () =>
      selectedRole.toucanAppsList.reduce(
        (acc, { toucanDomainsList }) => (acc += toucanDomainsList.length),
        0,
      ),
    [selectedRole],
  );

  const domainCount = useMemo(
    () =>
      allToucanApps.reduce((acc, { toucanDomainsList }) => (acc += toucanDomainsList.length), 0),
    [allToucanApps],
  );

  const handleCloseDialog = () => {
    setDialogOpen(null);
  };
  if (!selectedRole) {
    return null;
  }

  return (
    <div className={classes.root} data-cy="admin-role-permissions">
      <Typography className={classes.title} color="textSecondary" display="block" variant="button">
        permissions
      </Typography>

      <div className={classes.card}>
        <div className={classes.column}>
          <div className={classes.section}>
            <Typography className={classes.label}>
              Data Sources ({selectedSourceCount}/{domainCount})
            </Typography>
            <ButtonBase className={classes.textButton} onClick={() => setDialogOpen('domains')}>
              Select Data Sources
            </ButtonBase>
          </div>
          <div className={classes.section}>
            <Typography className={classes.label}>Geographies & Granularity</Typography>
            <ButtonBase className={classes.textButton} onClick={() => setDialogOpen('geo')}>
              Select Geo Boundaries
            </ButtonBase>
          </div>
          <div className={classes.section}>
            <Typography className={classes.label}>
              Signals ({selectedRole.signalsList.length}/{allSignals.length})
            </Typography>
            <ButtonBase className={classes.textButton} onClick={() => setDialogOpen('signals')}>
              Select Signals
            </ButtonBase>
          </div>
        </div>

        <div className={classes.column}>
          <div className={classes.section}>
            <Typography className={classes.label}>
              Teams ({matchedOrganization?.teamIds.length || 0}/{teamsByVertical.length})
            </Typography>
            <ButtonBase className={classes.textButton} onClick={() => setDialogOpen('teams')}>
              Select Teams
            </ButtonBase>
          </div>

          <div className={classes.section}>
            <Typography className={classes.label}>
              Endpoints ({selectedRole.endpointsList.length}/{allEndpoints.length})
            </Typography>
            <ButtonBase className={classes.textButton} onClick={() => setDialogOpen('endpoints')}>
              Select Endpoints
            </ButtonBase>
          </div>
        </div>

        <div className={classes.column}>
          <div className={classes.sectionStartDate}>
            <Typography className={classes.label}>Start Date</Typography>
            <div className={classes.sectionContents}>
              {/* // TODO: - look into the labelFunc prop on KeyboardDatePicker.
               Currently, there is a discrepancy between local time and UTC time as it gets later in the day.
               This may help, as we can put the labelFunc prop into the exact format we want using the passed in date obj, 
               and then potentially remove the need for the DateFnUtils altogether. */}
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="MM/dd/yyyy"
                inputVariant="standard"
                margin="dense"
                name="start-date"
                value={startDate}
                variant="inline"
                onChange={date => {
                  const newDate = date as Date;
                  setStartDate(newDate);

                  if (selectedRole.roleId && newDate && isValid(newDate)) {
                    patchRole.mutate({
                      role_id: selectedRole.roleId,
                      start_date: newDate.toISOString(),
                    });
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.section}>
            <Typography className={classes.label}>Forecast Length (if applicable)</Typography>
            <div className={classes.sectionContents}>
              <Typography>
                {forecastLength} {forecastLength !== 1 ? 'weeks' : 'week'}
              </Typography>
              <Slider
                aria-labelledby="forecast-slider"
                defaultValue={forecastLength}
                marks
                max={24}
                min={0}
                onChange={(_, val) => setForecastLength(val as number)}
                step={1}
                value={forecastLength}
                valueLabelDisplay="auto"
                onChangeCommitted={(_, val) => {
                  if (selectedRole.roleId && typeof val === 'number') {
                    patchRole.mutate({ role_id: selectedRole.roleId, days_in_future: val * 7 });
                  }
                }}
              />
              <Typography color="textSecondary" variant="caption">
                The forecast endpoint must be enabled for this to take effect.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <AdminRoleDomainsDialog
        className={classes.dialogContainer}
        open={dialogOpen === 'domains'}
        close={handleCloseDialog}
        allDomainsByToucanApp={allToucanApps}
        selectedRole={selectedRole}
      />

      <AdminRoleEndpointsDialog
        allEndpoints={allEndpoints}
        className={classes.dialogContainer}
        close={handleCloseDialog}
        open={dialogOpen === 'endpoints'}
        selectedRole={selectedRole}
      />

      <AdminSignalsDialog
        className={classes.dialogContainer}
        close={handleCloseDialog}
        open={dialogOpen === 'signals'}
        selectedRole={selectedRole}
        validSignals={allSignals}
      />

      <AdminTeamsDialog
        close={handleCloseDialog}
        matchedOrganization={matchedOrganization}
        open={dialogOpen === 'teams'}
        selectedRole={selectedRole}
        teamsByVertical={teamsByVertical}
      />

      <AdminRoleGeo
        className={classes.dialogContainer}
        close={handleCloseDialog}
        open={dialogOpen === 'geo'}
        selectedRole={selectedRole}
      />
    </div>
  );
};

export default AdminRolePermissions;
