import React from 'react';

import { RoleUser } from '@types';
import { ToastMessage } from 'components';

export const UserContext = React.createContext<RoleUser | null>(null);

//#region Toast
type ToastState = {
  type?: React.ComponentProps<typeof ToastMessage>['type'];
  title: string;
  message: string;
};

type Props = {
  children: React.ReactNode;
};

export const ToastContext = React.createContext<
  | {
      showToast: (toastConfig: ToastState) => void;
    }
  | undefined
>(undefined);

export const ToastProvider = ({ children }: Props) => {
  const [toastVisible, setToastVisible] = React.useState(false);
  const [toastConfig, setToastConfig] = React.useState<ToastState>({
    title: '',
    message: '',
  });

  const hideToast = () => setToastVisible(false);

  const value = React.useMemo(() => {
    const showToast = (toastConfig: ToastState) => {
      setToastConfig(toastConfig);
      setToastVisible(true);
    };
    return {
      showToast,
    };
  }, []);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastMessage
        type={toastConfig.type}
        message={toastConfig.message}
        title={toastConfig.title}
        hide={hideToast}
        isVisible={toastVisible}
      />
    </ToastContext.Provider>
  );
};
//#endregion Toast
