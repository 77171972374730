import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import logo from 'assets/images/insights-logo.svg';
import splashDesktop from 'assets/images/insights-splash-desktop.png';
import splashTablet from 'assets/images/insights-splash-tablet.png';
import splashMobile from 'assets/images/insights-splash-mobile.png';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'inherit',
      },
    },
    left: {
      flex: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    right: {
      position: 'relative',
      flex: 5,
      minHeight: '100%',
      [theme.breakpoints.down('sm')]: {
        flex: 'none',
        minHeight: '0%',
        height: 300,
      },
      [theme.breakpoints.down('xs')]: {
        height: 250,
      },
    },
    rightImage: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${splashDesktop})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${splashTablet})`,
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: `url(${splashMobile})`,
      },
    },
    rightText: {
      position: 'absolute',
      bottom: 20,
      left: 25,
    },
    logo: {
      maxHeight: 40,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    formWrapper: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 0 auto',
      alignSelf: 'center',
      width: '100%',
      maxWidth: 500,
      padding: theme.spacing(0, 2, 6, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
  }),
);

const FullscreenFormContainer = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <img src={logo} alt="Kinsa Insights" className={classes.logo} />
        <div className={classes.formWrapper}>{children}</div>
      </div>
      <div className={classes.right}>
        <div className={classes.rightImage} />
        <Typography className={classes.rightText} variant="body2">
          *Map is for demonstrative purposes only.
        </Typography>
      </div>
    </div>
  );
};

export default FullscreenFormContainer;
