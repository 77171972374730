import React, { useState, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ExpandableList, CheckboxDialog } from 'components';
import { RoleDetails, ToucanDomain } from '@types';
import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';

type Props = {
  dividerEl: React.ReactNode;
  roles: RoleDetails[];
};

type ToucanDomainWithRoles = ToucanDomain & { toucanAppName: string; roles: RoleDetails[] };
type ToucanDomainWithRolesCollection = Record<string, ToucanDomainWithRoles>;

const AdminDomainsByDomain = ({ dividerEl, roles }: Props) => {
  const queryClient = useQueryClient();
  const [editedDomain, setEditedDomain] = useState<ToucanDomainWithRoles | null>(null);

  // Used for checkboxes
  const roleOptions = useMemo(
    () =>
      roles
        .map(({ roleId, displayName, name }) => ({
          id: roleId?.toString() || '',
          label: displayName || name,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    [roles],
  );

  const { data: allToucanApps = [] } = useQuery(
    CACHE_KEYS.toucanDomains,
    adminAPI.getAllToucanDomains,
  );

  const { mutateAsync } = useMutation(adminAPI.bulkAssignRolesToToucanDomains, {
    // Returned data doesn't tell us which roles have been removed from updated domain, only added, so refetch all
    onSuccess: () => queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed),
  });

  // For each domain, place associated roles in "roles" array
  const domainsWithRoles = useMemo(() => {
    if (!allToucanApps.length) {
      return [];
    }

    // Create object with domain ID key to make lookup in next step simpler
    const domainsById = allToucanApps
      .map(app => ({
        ...app,
        toucanDomainsList: app.toucanDomainsList.map(domain => ({
          ...domain,
          toucanAppName: app.name,
        })),
      }))
      .flatMap(({ toucanDomainsList }) => toucanDomainsList)
      .reduce((acc, domain) => {
        acc[domain.toucanDomainId] = {
          ...domain,
          roles: [],
        };
        return acc;
      }, {} as ToucanDomainWithRolesCollection);

    // Associate role's domains with all domains
    roles.forEach(role => {
      role.toucanAppsList.forEach(({ toucanDomainsList }) => {
        toucanDomainsList.forEach(({ toucanDomainId }) => {
          domainsById[toucanDomainId]?.roles.push(role);
        });
      });
    });

    return Object.values(domainsById).sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }, [allToucanApps, roles]);

  const defaultSelectedOptions = useMemo(
    () => editedDomain?.roles.map(({ roleId }) => roleId?.toString() ?? ''),
    [editedDomain],
  );

  const handleSubmit = async (selectedOptions: string[]) => {
    if (editedDomain) {
      await mutateAsync({
        toucanDomainId: editedDomain.toucanDomainId,
        roleIds: selectedOptions.map(Number),
      });
    }
  };

  return (
    <>
      {domainsWithRoles.map(domain => (
        <React.Fragment key={domain.toucanDomainId}>
          <ExpandableList
            title={`${domain.name} (${domain.roles.length})`}
            subtitle={domain.toucanAppName}
            listItems={domain.roles.map(r => r.displayName ?? r.name)}
            buttonText="Select Organizations"
            handleClickButton={() => setEditedDomain(domain)}
          />
          {dividerEl}
        </React.Fragment>
      ))}

      <CheckboxDialog
        open={Boolean(editedDomain)}
        onClose={() => setEditedDomain(null)}
        onSubmit={handleSubmit}
        options={roleOptions}
        title={`Select Organizations linked to ‘${editedDomain?.name}’`}
        defaultSelectedOptions={defaultSelectedOptions}
        appendCountToTitle
        displayAllToggle
      />
    </>
  );
};

export default AdminDomainsByDomain;
