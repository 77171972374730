import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import download from 'downloadjs';
import _ from 'lodash';
import classNames from 'classnames';

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Publish as PublishIcon, GetApp as DownloadIcon } from '@material-ui/icons';

import { adminAPI } from 'api';
import { useCheckboxes } from 'hooks/forms';
import { ParsedGeography } from '@types';
import { CACHE_KEYS } from 'utils/constants';
import { DialogCustom } from '../..';
import { SharedProps } from '../types';

type Props = SharedProps & {
  parsedGeographies: ParsedGeography[] | null;
};

const SAMPLE_TEMPLATE = [
  {
    parent_region_type: 'state',
    parent_region_ids: ['AL', 'OR'],
    child_region_types: [],
  },
  {
    parent_region_type: 'county',
    parent_region_ids: ['01001', '01003'],
    child_region_types: [],
  },
  {
    parent_region_type: 'state',
    parent_region_ids: ['CA'],
    child_region_types: ['county'],
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    separatorText: {
      margin: theme.spacing(4, 0),
    },
    btn: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    gap: {
      marginRight: theme.spacing(1),
    },
  }),
);

const DefaultDialog = ({
  className,
  selectedRole,
  open,
  close,
  setViewMode,
  parsedGeographies,
}: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data: regionTypeOptions = [] } = useQuery(
    CACHE_KEYS.regionTypes,
    adminAPI.getAllRegionTypes,
    {
      // Put data into format that can be used by checkboxes, with National option at top
      select: rts =>
        rts
          .map(rt => ({ id: rt.name, label: rt.displayName }))
          .sort(a => (a.id === 'national' ? -1 : 1)),
    },
  );

  const { selectedOptions, setSelectedOptions, handleChange } = useCheckboxes(regionTypeOptions);

  const { mutate: updateGeographies, isLoading: isUpdatingGeographies } = useMutation(
    adminAPI.setGeographiesByRole,
    {
      onSuccess: newGeographies => {
        queryClient.setQueryData([CACHE_KEYS.geography, selectedRole.roleId], newGeographies);
        close();
      },
    },
  );

  useEffect(() => {
    // Reset checkbox state every time we open modal
    if (open) {
      const selectedGeographies = parsedGeographies?.flatMap(geo => geo.child_region_types) || [];
      setSelectedOptions(_.uniq(selectedGeographies));
    }
  }, [parsedGeographies, setSelectedOptions, open]);

  const handleSubmit = () => {
    updateGeographies({
      roleId: selectedRole.roleId,
      custom: false,
      geography: JSON.stringify([
        {
          parent_region_type: 'national',
          parent_region_ids: ['US'],
          child_region_types: selectedOptions,
        },
      ]),
    });
  };

  const downloadTemplate = () =>
    download(JSON.stringify(SAMPLE_TEMPLATE, null, 4), 'geo_template.json', 'application/json');

  return (
    <DialogCustom
      data-testid="geo-dialog"
      classes={{ paper: className }}
      transitionDuration={0}
      open={open}
      onClose={close}
      title="Select Geo Boundaries"
      label="select geo boundaries"
      actions={
        <>
          <Button onClick={close} color="primary" disabled={isUpdatingGeographies}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isUpdatingGeographies}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl component="fieldset">
        <FormGroup>
          {regionTypeOptions.map(option => {
            const isDefaultCheckbox = option.id === 'national';
            return (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedOptions.includes(option.id) || isDefaultCheckbox}
                    onChange={handleChange}
                    name={option.id}
                    disabled={isDefaultCheckbox}
                  />
                }
                label={option.label}
              />
            );
          })}
        </FormGroup>

        <Typography className={classes.separatorText} color="textPrimary">
          OR
        </Typography>

        <Box>
          <Button
            className={classNames(classes.btn, classes.gap)}
            variant="outlined"
            color="primary"
            onClick={() => setViewMode('upload json')}
          >
            <PublishIcon />
            &nbsp; Upload JSON file
          </Button>
          <Button className={classes.btn} color="primary" onClick={downloadTemplate}>
            <DownloadIcon />
            &nbsp; Download Template
          </Button>
        </Box>
      </FormControl>
    </DialogCustom>
  );
};

export default DefaultDialog;
