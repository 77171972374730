export const COOKIE_NAMES = {
  accessToken: 'access_token',
  email: 'email',
  refreshToken: 'refresh_token',
};

export const APP_HEADER_HEIGHT = 92;

export const ADMIN_ROLE_NAME = 'internal_superuser';

export const CACHE_KEYS = {
  endpoints: 'endpoints',
  geography: 'geography',
  organizationsDetailed: 'organizations_detailed',
  regionTypes: 'region_types',
  rolesDetailed: 'roles_detailed',
  signals: 'signals',
  teams: 'teams',
  toucanDomains: 'toucan_domains',
  users: 'users',
};

export const CATEGORIES = {
  ipc: 'IPC',
  healthcare: 'Healthcare',
  publicSector: 'Public Sector',
  none: 'None',
};

export const SESSION_POLL_INTERVAL = 1000 * 10; // 10 seconds

export const AUTH_TOKEN_EXPIRATION = 1000 * 60 * 14.5; // ~15 minutes
