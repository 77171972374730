import { useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { useCheckboxes } from 'hooks/forms';
import { DialogCustom } from '..';

type Props = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedOptions: string[]) => Promise<unknown>;
  options: { id: string; label: string }[];
  defaultSelectedOptions?: string[];
  displayAllToggle?: boolean;
  title: string;
  appendCountToTitle: boolean;
};

const CheckboxDialog = ({
  className,
  open,
  onClose,
  onSubmit,
  options,
  displayAllToggle,
  title,
  appendCountToTitle,
  defaultSelectedOptions,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    isAllChecked,
    isIndeterminateChecked,
    selectedOptions,
    setSelectedOptions,
    handleChange,
    handleChangeAll,
  } = useCheckboxes(options);

  useEffect(() => {
    if (open) {
      // Reset when opening dialog
      setSelectedOptions(defaultSelectedOptions ?? []);
    }
  }, [open, setSelectedOptions, defaultSelectedOptions]);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await onSubmit(selectedOptions);
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      maxWidth="xs"
      transitionDuration={0}
      open={open}
      onClose={onClose}
      title={title + (appendCountToTitle ? ` (${selectedOptions.length}/${options.length})` : '')}
      label={title}
      actions={
        <>
          <Button onClick={onClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          &nbsp;
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl component="fieldset" data-testid={title}>
        <FormGroup>
          {displayAllToggle && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  indeterminate={isIndeterminateChecked}
                  checked={isAllChecked}
                  onChange={handleChangeAll}
                  name="all"
                />
              }
              label="All"
            />
          )}

          {options.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleChange}
                  name={option.id}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </DialogCustom>
  );
};

export default CheckboxDialog;
