import { useState } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, SvgIcon, ButtonBase, Button } from '@material-ui/core';

type Props = {
  title: string;
  listItems: string[];
  buttonText: string;
  handleClickButton: () => void;
  subtitle?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 36,
    },
    titleLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    titleWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    title: {
      marginLeft: theme.spacing(1),
      fontWeight: 500,
    },
    subtitle: {
      ...theme.typography.body2,
      paddingLeft: theme.spacing(3),
      textAlign: 'left',
    },
    button: {
      ...theme.typography.body1,
    },
    buttonBase: {
      padding: theme.spacing(0.75, 0),
    },
    buttonWithSubtitle: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
    list: {
      padding: theme.spacing(1, 0, 0, 4),
      margin: '0 0 18px 7px',
      borderLeft: `1px dashed ${theme.palette.text.secondary}`,
    },
    listItem: { paddingBottom: theme.spacing(0.5), marginBottom: theme.spacing(1) },
  }),
);

const MinusSquare = (props: React.ComponentProps<typeof SvgIcon>) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
};

const PlusSquare = (props: React.ComponentProps<typeof SvgIcon>) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
};

const ExpandableList = ({ title, listItems, buttonText, handleClickButton, subtitle }: Props) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const [isHovered, setHovered] = useState(false);

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <div className={classes.titleRow}>
        <div className={classes.titleLeft}>
          <ButtonBase
            className={classNames(classes.buttonBase, {
              [classes.buttonWithSubtitle]: !!subtitle,
            })}
            onClick={() => setExpanded(state => !state)}
            disabled={!listItems.length}
          >
            {!!subtitle ? (
              <>
                <div className={classes.titleWrapper}>
                  {isExpanded ? <MinusSquare /> : <PlusSquare />}
                  <Typography className={classes.title}>{title}</Typography>
                </div>
                <Typography color="textSecondary" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </>
            ) : (
              <>
                {isExpanded ? <MinusSquare /> : <PlusSquare />}
                <Typography className={classes.title}>{title}</Typography>
              </>
            )}
          </ButtonBase>
        </div>

        {(isExpanded || isHovered) && (
          <Button onClick={handleClickButton} color="primary" className={classes.button}>
            {buttonText}
          </Button>
        )}
      </div>

      {isExpanded && (
        <ul className={classes.list}>
          {listItems.map(text => (
            <Typography key={text} className={classes.listItem} component="li">
              {text}
            </Typography>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ExpandableList;
