import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { adminAPI } from 'api';
import { DialogCustom } from '..';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  close: () => void;
  open: boolean;
  organizationId: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMsg: {
      marginTop: theme.spacing(2),
    },
    form: {
      border: `1px solid ${theme.palette.kinsaGrey200}`,
      borderRadius: 4,
      padding: theme.spacing(1.5),
    },
    link: {
      color: theme.palette.primary.main,
    },
  }),
);

const AdminCsvUploadDialog = ({ close, open, organizationId }: Props) => {
  const classes = useStyles();
  const [csvFile, setCsvFile] = React.useState<File>();
  const queryClient = useQueryClient();

  const {
    mutate: bulkAssignOrganizations,
    isLoading,
    error,
    reset,
  } = useMutation(adminAPI.bulkAssignCsvUpload, {
    onSuccess: () => {
      // Refetch everything b/c it's hard to know if a user was created or re-assigned
      queryClient.invalidateQueries(CACHE_KEYS.users);
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
      handleClose();
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      setCsvFile(file);
    }
  };

  const handleSubmit = () => {
    if (csvFile) {
      bulkAssignOrganizations({
        organizationId,
        file: csvFile,
      });
    }
  };

  const handleClose = () => {
    setCsvFile(undefined);
    reset();
    close();
  };

  return (
    <DialogCustom
      label="import-users-csv"
      onClose={(event, reason) => {
        // Don't allow the dialog to close during request
        if (!isLoading) {
          handleClose();
        }
      }}
      open={open}
      title="Import CSV"
      actions={
        <>
          <Button color="primary" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading || !csvFile}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Import
          </Button>
        </>
      }
    >
      <Typography paragraph>
        File structure must be the same as{' '}
        <a
          className={classes.link}
          href="https://docs.google.com/spreadsheets/d/1e_xXU42ZSJoSq-BGQzF3fYhN0xur7mI_ZQbw3DFUOnM/edit#gid=0"
          rel="noopener noreferrer"
          target="_blank"
        >
          template
        </a>
        .
      </Typography>

      <form className={classes.form}>
        <input
          accept="text/csv"
          id="csv-upload"
          multiple={false}
          name="csv-upload"
          onChange={handleFileChange}
          type="file"
        />
      </form>

      {error && (
        <Typography className={classes.errorMsg} color="error">
          There was an error uploading the CSV.
        </Typography>
      )}
    </DialogCustom>
  );
};

export default AdminCsvUploadDialog;
