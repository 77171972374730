import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { CheckboxDialog, ExpandableList } from 'components';
import { RoleDetails } from '@types';
import { adminAPI } from 'api';
import { CACHE_KEYS } from 'utils/constants';
import { usePatchRole } from 'hooks/roles';

type Props = {
  dividerEl: React.ReactNode;
  roles: RoleDetails[];
};

const AdminEndpointsByRole = ({ dividerEl, roles }: Props) => {
  const [editedRole, setEditedRole] = useState<RoleDetails | null>(null);
  const patchRole = usePatchRole();

  const { data: endpointOptions = [] } = useQuery(CACHE_KEYS.endpoints, adminAPI.getAllEndpoints, {
    select: endpoints =>
      endpoints
        .map(e => ({
          id: e.endpointId.toString(),
          label: e.name,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
  });

  const defaultSelectedOptions = useMemo(() => {
    return editedRole?.endpointsList.map(e => e.endpointId.toString());
  }, [editedRole]);

  const handleSubmit = async (selectedOptions: string[]) => {
    if (editedRole?.roleId) {
      await patchRole.mutateAsync({
        role_id: editedRole.roleId,
        endpoints: selectedOptions.map(Number),
      });
    }
  };

  return (
    <>
      {roles.map(role => (
        <React.Fragment key={role.roleId}>
          <ExpandableList
            title={`${role.displayName || role.name} (${role.endpointsList.length})`}
            listItems={role.endpointsList.map(e => e.name)}
            buttonText="Select Endpoints"
            handleClickButton={() => setEditedRole(role)}
          />
          {dividerEl}
        </React.Fragment>
      ))}

      <CheckboxDialog
        open={Boolean(editedRole)}
        onClose={() => setEditedRole(null)}
        onSubmit={handleSubmit}
        options={endpointOptions}
        title={`Select Endpoints for ${editedRole?.displayName || editedRole?.name}`}
        defaultSelectedOptions={defaultSelectedOptions}
        appendCountToTitle
        displayAllToggle
      />
    </>
  );
};

export default AdminEndpointsByRole;
