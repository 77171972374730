import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';

import { AdminTeamsEditDialog, ExpandableList } from 'components';
import { CACHE_KEYS, CATEGORIES } from 'utils/constants';
import { adminAPI } from 'api';

import { TeamsByCategoryCollection } from '../AdminTeamsDialog/types';

type Props = {
  dividerEl: React.ReactNode;
};

const AdminTeamsByCategory = ({ dividerEl }: Props) => {
  const [editingCategory, setEditingCategory] = useState<string>('');

  const { data: teams = [] } = useQuery(CACHE_KEYS.teams, adminAPI.getAllTeams);

  const teamsByCategory = useMemo(
    () =>
      teams.reduce((acc, team) => {
        if (!acc[team.category]) {
          acc[team.category] = [];
        }

        acc[team.category].push(team);

        return acc;
      }, {} as TeamsByCategoryCollection),
    [teams],
  );

  const handleClose = () => setEditingCategory('');

  return (
    <>
      {Object.values(CATEGORIES).map((key, i) => (
        <React.Fragment key={`${key}-${i}`}>
          <ExpandableList
            title={key}
            listItems={teamsByCategory[key]?.map(team => team.name) || []}
            buttonText="Edit or Add Teams"
            handleClickButton={() => setEditingCategory(key)}
          />
          {dividerEl}
        </React.Fragment>
      ))}

      <AdminTeamsEditDialog
        open={Boolean(editingCategory)}
        category={editingCategory}
        close={handleClose}
        teamsByCategory={teamsByCategory}
      />
    </>
  );
};

export default AdminTeamsByCategory;
