import { useEffect, useState } from 'react';
import qs from 'query-string';
import { Formik, FormikHelpers, FormikProps, Form, Field } from 'formik';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Link } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import { TextInput } from '../Formik';
import { FullscreenFormContainer, ErrorText, RouterLink } from '../';
import { routes } from 'router/routes';
import { setDocumentTitle, setAuthCookies } from 'utils/browser';
import { FORM_VALIDATION } from 'utils/form';
import { authAPI } from 'api';

type FormValues = {
  password1: string;
  password2: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    btn: {
      margin: theme.spacing(4, 0, 2),
    },
  }),
);

const ResetPassword = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState<string | null>(null);
  const [recoveryToken, setRecoveryToken] = useState('');

  useEffect(() => {
    const { t } = qs.parse(history.location.search);
    if (typeof t === 'string') {
      setRecoveryToken(t);
    }

    setDocumentTitle(routes.resetPassword.label);
  }, [history]);

  return (
    <FullscreenFormContainer>
      <div className={classes.root}>
        <Typography variant="h4">Reset your password</Typography>

        <Formik<FormValues>
          initialValues={{
            password1: '',
            password2: '',
          }}
          validate={FORM_VALIDATION.repeatPasswords}
          onSubmit={async (
            { password1 }: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>,
          ) => {
            try {
              setSubmitting(true);
              const tokenSet = await authAPI.recoverAccount(recoveryToken, password1);
              setServerError(null);
              setAuthCookies(tokenSet);
              history.push(routes.root.value);
            } catch (err) {
              const errorResp = err.response ? await err.response.json() : null;
              const errorMsg = _.get(errorResp, 'errors[0].message', 'Error resetting password.');
              setServerError(errorMsg);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }: FormikProps<FormValues>) => (
            <Form noValidate className={classes.form}>
              <Field
                required
                name="password1"
                type="password"
                label="New password"
                margin="normal"
                component={TextInput}
              />
              <Field
                required
                name="password2"
                type="password"
                label="Repeat new password"
                margin="normal"
                component={TextInput}
              />

              <Button
                className={classes.btn}
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Reset Password
              </Button>

              <Link
                variant="body2"
                align="center"
                color="primary"
                component={RouterLink}
                to={routes.login.value}
              >
                Remember password?
              </Link>

              <ErrorText text={serverError} />
            </Form>
          )}
        </Formik>
      </div>
    </FullscreenFormContainer>
  );
};

export default ResetPassword;
