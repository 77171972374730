export const routes = {
  root: {
    value: '/',
    label: 'Admin Dashboard',
  },
  login: {
    value: '/login',
    label: 'Login',
  },
  resetPassword: {
    value: '/account-recovery',
    label: 'Reset Password',
  },
  forgotPassword: {
    value: '/forgot-password',
    label: 'Forgot Password',
  },
  claimAccount: {
    value: '/claim-account',
    label: 'Claim Account',
  },
  notFound: {
    value: '/404',
    label: 'Page Not Found',
  },
  account: {
    value: '/my-account',
    label: 'My Account',
  },
} as const;
