import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import Cookie from 'js-cookie';
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { setDocumentTitle, setAuthCookies, deleteAuthCookies } from 'utils/browser';
import { useSessionPoll, useTokenRefreshInterval, useWatchKeyboard } from 'hooks/browser';
import { routes } from 'router/routes';
import { App } from '../';
import { ADMIN_ROLE_NAME, COOKIE_NAMES } from 'utils/constants';
import { authAPI } from 'api';
import { UserContext } from 'config/context';
import { RoleUser } from '@types';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
    },
  }),
);

const AppProvider = ({
  children,
  location: { pathname },
  history,
}: Props & RouteComponentProps) => {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState<RoleUser | null>(null);
  useSessionPoll();
  useTokenRefreshInterval();
  useWatchKeyboard();

  // #region Setup

  // Verify access token is valid before fetching user data
  useEffect(() => {
    const token = Cookie.get(COOKIE_NAMES.refreshToken);

    // If no token, don't bother making request that will fail anyways
    if (!token) {
      history.push(routes.login.value);
      return;
    }

    authAPI
      .refreshTokenSet()
      .then(tokenSet => {
        setAuthCookies(tokenSet);
        return authAPI.getUserInfo();
      })
      .then(user => {
        const isAdminUser = user.roleName === ADMIN_ROLE_NAME;
        if (isAdminUser) {
          setCurrentUser(user);
        } else {
          deleteAuthCookies();
          history.push(routes.login.value);
        }
      })
      .catch(() => {
        deleteAuthCookies();
        history.push(routes.login.value);
      });
  }, [history]);

  // Set document.title on route change
  useEffect(() => {
    const route = _.find(routes, route => route.value.includes(pathname));
    if (route) {
      setDocumentTitle(route.label);
    }
  }, [pathname]);

  // #endregion Setup

  // App loading spinner
  if (!currentUser) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <UserContext.Provider value={currentUser}>
      <App pathname={pathname}>{children}</App>
    </UserContext.Provider>
  );
};

export default AppProvider;
