import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Snackbar, IconButton, Typography } from '@material-ui/core';
import {
  CloseRounded as CloseIcon,
  CheckCircleRounded as CheckCircleIcon,
} from '@material-ui/icons';

type NotificationType = 'success' | 'warn';

type Props = {
  type?: NotificationType;
  title: string;
  message: string | React.ReactNode;
  isVisible: boolean;
  hide: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ type }: Props) => ({
      maxWidth: 400,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: type === 'warn' ? theme.palette.error.main : 'transparent',
      backgroundColor: type === 'warn' ? '#ffebea' : theme.palette.text.primary,
    }),
    messageContainer: {
      width: '100%',
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    messageTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'align-items',
    },
    title: ({ type }: Props) => ({
      color: type === 'warn' ? theme.palette.error.main : 'white',
      textTransform: 'uppercase',
    }),
    label: ({ type }: Props) => ({
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.caption.fontSize,
      color: type === 'warn' ? theme.palette.text.primary : 'white',
    }),
    close: ({ type }: Props) => ({
      color: type === 'warn' ? theme.palette.error.main : 'white',
      padding: theme.spacing(0.5),
    }),
  }),
);

const renderIcon = (type?: NotificationType) => {
  const style = { marginRight: 8 };
  switch (type) {
    case 'success':
      return <CheckCircleIcon style={style} />;
    default:
      return null;
  }
};

const ToastMessage = (props: Props) => {
  const { type, title, message, isVisible, hide } = props;
  const classes = useStyles(props);

  return (
    <Snackbar
      data-cy={`qn-${type}`}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isVisible}
      autoHideDuration={5000}
      onClose={hide}
      ContentProps={{
        classes: {
          root: classes.root,
          message: classes.messageContainer,
        },
        elevation: 0,
        'aria-describedby': 'message-id',
      }}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
      message={
        <span id="message-id" className={classes.message}>
          {/* Icon + Title + Action */}
          <div className={classes.messageTop}>
            <span className={classes.titleContainer}>
              {renderIcon(type)}
              <Typography className={classes.title} variant="subtitle2">
                {title}
              </Typography>
            </span>
            <IconButton key="close" aria-label="Close" className={classes.close} onClick={hide}>
              <CloseIcon />
            </IconButton>
          </div>

          {/* Notification text */}
          <div className={classes.label}>{message}</div>
        </span>
      }
    />
  );
};

export default ToastMessage;
