import { useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { RoleDetails, ToucanApp } from '@types';
import { useNestedCheckboxes } from 'hooks/forms';
import { usePatchRole } from 'hooks/roles';
import { CheckboxGroup, DialogCustom } from '..';

type Props = {
  selectedRole: RoleDetails | null;
  open: boolean;
  close: () => void;
  className: string;
  allDomainsByToucanApp: ToucanApp[];
  title?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContainer: {
      minWidth: 640,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    listItemRoot: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    listItemText: {
      marginLeft: theme.spacing(1),
    },
    listItemSecondary: {
      position: 'unset',
      transform: 'none',
    },
    checkbox: {
      color: theme.palette.primary.main,
      padding: '3px',
    },
  }),
);

const AdminRoleDomainsDialog = ({
  className,
  selectedRole,
  allDomainsByToucanApp,
  open,
  close,
  title,
}: Props) => {
  const classes = useStyles();

  const { mutate, isLoading } = usePatchRole(() => close());

  const domainCount = useMemo(
    () =>
      allDomainsByToucanApp.reduce(
        (acc, { toucanDomainsList }) => (acc += toucanDomainsList.length),
        0,
      ),
    [allDomainsByToucanApp],
  );

  const domainOptions = useMemo(
    () =>
      allDomainsByToucanApp.map(({ toucanAppId, name, toucanDomainsList }) => ({
        parentId: toucanAppId.toString(),
        parentLabel: name,
        children: toucanDomainsList.map(({ toucanDomainId, name: domainName }) => ({
          id: toucanDomainId.toString(),
          label: domainName,
        })),
      })),
    [allDomainsByToucanApp],
  );

  const {
    isAllChecked,
    isIndeterminateChecked,
    selectedOptions,
    setSelectedOptions,
    handleChange,
    handleChangeAll,
    handleParentChange,
  } = useNestedCheckboxes(domainOptions);

  useEffect(() => {
    if (open && selectedRole) {
      const defaultSelected: string[] = [];
      selectedRole?.toucanAppsList.forEach(({ toucanDomainsList }) =>
        toucanDomainsList.forEach(({ toucanDomainId }) =>
          defaultSelected.push(toucanDomainId.toString()),
        ),
      );
      setSelectedOptions(defaultSelected);
    }
  }, [open, setSelectedOptions, selectedRole]);

  const handleSubmit = () => {
    if (selectedRole?.roleId) {
      mutate({
        role_id: selectedRole.roleId,
        toucan_domains: selectedOptions.map(Number),
      });
    }
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      open={open}
      onClose={close}
      title={title ? title : `Select Data Sources (${selectedOptions.length}/${domainCount})`}
      label="select data sources"
      alwaysShowScrollbar={true}
      maxWidth="md"
      actions={
        <>
          <Button onClick={close} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          &nbsp;
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl component="fieldset" data-testid="domains-dialog">
        <List>
          <ListItem
            classes={{
              container: classes.listItemContainer,
              root: classes.listItemRoot,
            }}
            disableGutters
          >
            <ListItemText primary="All" className={classes.listItemText} />
            <ListItemSecondaryAction className={classes.listItemSecondary}>
              <Checkbox
                color="primary"
                className={classes.checkbox}
                indeterminate={isIndeterminateChecked}
                checked={isAllChecked}
                onChange={handleChangeAll}
                name="all"
              />
            </ListItemSecondaryAction>
          </ListItem>
          {domainOptions.map(option => (
            <CheckboxGroup
              key={option.parentId}
              option={option}
              selectedOptions={selectedOptions}
              maxWidth={576}
              handleChange={handleChange}
              handleParentChange={handleParentChange}
            />
          ))}
        </List>
      </FormControl>
    </DialogCustom>
  );
};

export default AdminRoleDomainsDialog;
