import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { CACHE_KEYS } from 'utils/constants';
import { adminAPI } from 'api';
import { ParsedGeography } from '@types';
import { ViewMode, BaseProps } from './types';
import { DefaultDialog, UploadJsonDialog, ViewJsonDialog, DeleteJsonDialog } from './dialogs';

const AdminRoleGeo = (props: BaseProps) => {
  const { roleId } = props.selectedRole;
  const [viewMode, setViewMode] = useState<ViewMode>('default');
  const [parsedGeographies, setParsedGeographies] = useState<ParsedGeography[] | null>(null);

  const { data: geographiesData } = useQuery([CACHE_KEYS.geography, roleId], () =>
    adminAPI.getGeographiesByRole(roleId),
  );

  useEffect(() => {
    if (props.open) {
      if (!geographiesData) {
        return;
      }

      if (geographiesData.custom) {
        setViewMode(geographiesData.geography ? 'view json' : 'upload json');
      } else {
        setViewMode('default');
      }

      // Actual selected geographies are sent from server as JSON string, so have to parse before using
      const parsedGeographies = geographiesData.geography
        ? JSON.parse(geographiesData.geography)
        : null;
      setParsedGeographies(parsedGeographies);
    }
  }, [geographiesData, props.open]);

  if (!geographiesData) {
    return null;
  }

  switch (viewMode) {
    case 'default':
      return (
        <DefaultDialog {...props} setViewMode={setViewMode} parsedGeographies={parsedGeographies} />
      );
    case 'upload json':
      return <UploadJsonDialog {...props} setViewMode={setViewMode} />;
    case 'view json':
      return (
        <ViewJsonDialog
          {...props}
          setViewMode={setViewMode}
          parsedGeographies={parsedGeographies}
        />
      );
    case 'delete json':
      return <DeleteJsonDialog {...props} setViewMode={setViewMode} />;
    default:
      return null;
  }
};

export default AdminRoleGeo;
