import { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

import { setDocumentTitle } from 'utils/browser';
import { routes } from 'router/routes';
import { RouterLink } from '../';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const FourOhFour = () => {
  const classes = useStyles();

  useEffect(() => {
    setDocumentTitle(routes.notFound.label);
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header} align="center">
        Page Not Found
      </Typography>
      <Link
        variant="body2"
        align="center"
        color="primary"
        component={RouterLink}
        to={routes.root.value}
      >
        Return Home
      </Link>
    </div>
  );
};

export default FourOhFour;
