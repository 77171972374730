import { useState } from 'react';

import { OrganizationDetails, Team } from '@types';

import { BaseProps } from './types';
import {
  AdminTeamCreateDialog,
  EditTeamDialog,
  DeleteTeamDialog,
  SelectTeamsDialog,
} from './dialogs';
import { VIEW_MODE } from './dialogs/constants';

type Props = BaseProps & {
  matchedOrganization?: OrganizationDetails;
  teamsByVertical: Team[];
};

const AdminTeamsDialog = ({
  open,
  close,
  matchedOrganization,
  teamsByVertical,
  selectedRole,
}: Props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.viewTeams);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [newlyCreatedTeams, setNewlyCreatedTeams] = useState<Team[]>([]);

  const resetDialog = () => {
    setSelectedTeam(undefined);
    setNewlyCreatedTeams([]);
    setViewMode(VIEW_MODE.viewTeams);
    close();
  };

  const props = {
    close: resetDialog,
    category: selectedRole?.category || 'None',
    open,
    setViewMode,
  };

  switch (viewMode) {
    case VIEW_MODE.createTeam:
      return (
        <AdminTeamCreateDialog
          {...props}
          setNewlyCreatedTeams={setNewlyCreatedTeams}
          newlyCreatedTeams={newlyCreatedTeams}
        />
      );
    case VIEW_MODE.editTeam:
      return <EditTeamDialog {...props} team={selectedTeam} />;
    case VIEW_MODE.deleteTeam:
      return <DeleteTeamDialog {...props} team={selectedTeam} />;
    case VIEW_MODE.viewTeams:
    default:
      return (
        <SelectTeamsDialog
          {...props}
          selectedRole={selectedRole}
          matchedOrganization={matchedOrganization}
          teamsByVertical={teamsByVertical}
          setSelectedTeam={setSelectedTeam}
          newlyCreatedTeams={newlyCreatedTeams}
        />
      );
  }
};

export default AdminTeamsDialog;
