import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Divider, Button } from '@material-ui/core';

import { AdminDomainsByDomain, AdminDomainsByRole } from 'components';
import { useAllRoles } from 'hooks/roles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(5, 'auto'),
      maxWidth: theme.breakpoints.values.md,
    },
    title: {
      fontWeight: 700,
    },
    content: {
      marginTop: theme.spacing(4),
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      paddingBottom: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabLabel: {
      marginRight: theme.spacing(1),
    },
    tabButton: {
      ...theme.typography.body1,
      borderRadius: 0,
    },
    tabButtonActive: {
      fontWeight: 700,
      boxShadow: `0 3px 0 0 ${theme.palette.primary.main}`,
    },
    divider: {
      margin: theme.spacing(1.5, 0),
    },
  }),
);

const AdminDomainsPanel = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<'domains' | 'roles'>('domains');

  const { data: allRoles = [] } = useAllRoles(true);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Data Sources
      </Typography>
      <div className={classes.content}>
        <div className={classes.tabs}>
          <Typography className={classes.tabLabel}>View by:</Typography>
          <Button
            className={`${classes.tabButton} ${
              selectedTab === 'domains' ? classes.tabButtonActive : ''
            }`}
            onClick={() => setSelectedTab('domains')}
          >
            Data Source
          </Button>
          <Button
            className={`${classes.tabButton} ${
              selectedTab === 'roles' ? classes.tabButtonActive : ''
            }`}
            onClick={() => setSelectedTab('roles')}
          >
            Organization
          </Button>
        </div>

        {selectedTab === 'domains' && (
          <AdminDomainsByDomain
            roles={allRoles}
            dividerEl={<Divider className={classes.divider} />}
          />
        )}

        {selectedTab === 'roles' && (
          <AdminDomainsByRole
            roles={allRoles}
            dividerEl={<Divider className={classes.divider} />}
          />
        )}
      </div>
    </div>
  );
};

export default AdminDomainsPanel;
