// TODO: if we change sidebar to hit /organizations instead of /roles - change all references of selectedRole to selectedOrganization

import React from 'react';
import { useQuery } from 'react-query';

import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

import { adminAPI } from 'api';
import { RoleDetails } from '@types';
import {
  AdminBulkUserDialog,
  AdminCsvUploadDialog,
  AdminRoleUsersTable,
  AdminSingleUserDialog,
} from '..';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  isAllUsersView: boolean;
  selectedRole?: RoleDetails;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addBtn: {
      textTransform: 'uppercase',
    },
    btnRow: {
      margin: theme.spacing(6, 0, 2),
    },
    loading: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
    },
  }),
);

const AdminRoleUsers = ({ isAllUsersView, selectedRole }: Props) => {
  const classes = useStyles();
  const [importDialogOpen, setImportDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userDialogType, setUserDialogType] = React.useState<'new' | 'existing' | null>(null);

  const { data: allUsers = [], isFetching: isFetchingAllUsers } = useQuery(
    CACHE_KEYS.users,
    adminAPI.getAllUsers,
  );

  const users = allUsers.filter(
    user => isAllUsersView || user.organizationId === selectedRole?.organizationId,
  );
  const unassignedUsers = allUsers.filter(user => user.roleName === 'default');

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isFetchingAllUsers) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.btnRow}>
        {!isAllUsersView && (
          <>
            <Button
              data-cy="add-user-btn"
              aria-controls="add-user-menu"
              aria-haspopup="true"
              className={classes.addBtn}
              color="primary"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <AddIcon />
              &nbsp;add user(s)
              <ArrowDropDownIcon />
            </Button>
            <Button
              className={classes.addBtn}
              color="primary"
              onClick={() => setImportDialogOpen(true)}
            >
              import csv
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              getContentAnchorEl={null}
              id="add-user-menu"
              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
            >
              <MenuItem
                onClick={() => {
                  setUserDialogType('new');
                  handleClose();
                }}
              >
                New user
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUserDialogType('existing');
                  handleClose();
                }}
              >
                From existing users
              </MenuItem>
            </Menu>

            {selectedRole?.organizationId && (
              <>
                <AdminSingleUserDialog
                  close={() => setUserDialogType(null)}
                  open={userDialogType === 'new'}
                  organizationId={selectedRole.organizationId}
                />

                <AdminBulkUserDialog
                  close={() => setUserDialogType(null)}
                  open={userDialogType === 'existing'}
                  role={selectedRole}
                  unassignedUsers={unassignedUsers}
                />
              </>
            )}
          </>
        )}
      </div>

      <AdminRoleUsersTable isAllUsersView={isAllUsersView} users={users} />

      {selectedRole?.organizationId && (
        <AdminCsvUploadDialog
          close={() => setImportDialogOpen(false)}
          open={importDialogOpen}
          organizationId={selectedRole.organizationId}
        />
      )}
    </div>
  );
};

export default AdminRoleUsers;
