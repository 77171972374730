import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Menu, MenuItem } from '@material-ui/core';

import { adminAPI } from 'api';
import { authAPI } from 'api';
import { RoleUser } from '@types';
import { AdminSingleUserDialog } from '..';
import { useToastContext } from 'hooks/context';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  user: RoleUser;
};

const AdminRoleUsersTableMenu = ({ anchorEl, handleClose, user }: Props) => {
  const [showEditMenu, setShowEditMenu] = React.useState(false);
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  const { mutate: deleteUserMutate } = useMutation(adminAPI.deleteUser, {
    onSuccess: () => {
      // Update cache after successful delete
      const allUsers = queryClient.getQueryData<RoleUser[]>(CACHE_KEYS.users) || [];
      const updatedUsers = allUsers.filter(u => u.id !== user.id);
      queryClient.setQueryData(CACHE_KEYS.users, updatedUsers);
      // Refetch user-count because per-role counts will have changed
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
    },
  });

  const { mutate: updateUserMutate } = useMutation(adminAPI.patchUser, {
    onSuccess: updatedUser => {
      // Update cache after successful update
      const allUsers = queryClient.getQueryData<RoleUser[]>(CACHE_KEYS.users) || [];
      const updatedUsers = allUsers.map(user => {
        return user.id === updatedUser.id ? { ...user, ...updatedUser } : user;
      });
      queryClient.setQueryData(CACHE_KEYS.users, updatedUsers);
      // Refetch user-count because per-role counts will have changed
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
    },
  });

  const handleShowEdit = () => {
    setShowEditMenu(true);
    handleClose();
  };

  const handleResendInviteEmail = async () => {
    handleClose();

    try {
      await authAPI.sendActivationEmail(user.email);
      showToast({
        type: 'success',
        title: 'success!',
        message: 'Invitation resent.',
      });
    } catch (err) {
      showToast({
        type: 'warn',
        title: 'error',
        message: 'Error resending invitation.',
      });
    }
  };

  const handleSendAccountRecoveryEmail = async () => {
    handleClose();

    try {
      await authAPI.forgotPassword(user.email);
      showToast({
        type: 'success',
        title: 'success!',
        message: 'Account recovery email sent.',
      });
    } catch (err) {
      showToast({
        type: 'warn',
        title: 'error',
        message: 'Error sending account recovery email.',
      });
    }
  };

  const handleRemoveUserFromOrg = () => {
    updateUserMutate({ id: user.id, organization_id: null });
    handleClose();
  };

  const handleDeleteUser = () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUserMutate({ id: user.id });
    }
    handleClose();
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        id="user-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={handleShowEdit}>Edit</MenuItem>
        {user.activationToken && (
          <MenuItem onClick={handleResendInviteEmail}>Resend invite</MenuItem>
        )}
        {!user.activationToken && (
          <MenuItem onClick={handleSendAccountRecoveryEmail}>Send account recovery</MenuItem>
        )}
        {user.roleName !== 'default' && (
          <MenuItem onClick={handleRemoveUserFromOrg}>Remove from organization</MenuItem>
        )}
        <MenuItem onClick={handleDeleteUser}>Delete user</MenuItem>
      </Menu>

      {user && (
        <AdminSingleUserDialog
          open={showEditMenu}
          close={() => setShowEditMenu(false)}
          user={user}
        />
      )}
    </div>
  );
};

export default AdminRoleUsersTableMenu;
