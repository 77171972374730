import ky from 'ky';
import Cookie from 'js-cookie';

import { COOKIE_NAMES } from 'utils/constants';
import { setAuthCookies } from 'utils/browser';

const PREFIX_URL = (process.env.REACT_APP_DATASVC_URI || '') + 'api/v1/';

export const insightsKy = ky.extend({
  prefixUrl: PREFIX_URL,
  timeout: 1000 * 60, // 60 sec
  retry: {
    limit: 2,
    methods: ['get', 'post', 'delete', 'head', 'put', 'patch'],
    statusCodes: [401, 403, 500, 502, 503, 504],
  },
  hooks: {
    beforeRequest: [
      request => {
        const accessToken = Cookie.get(COOKIE_NAMES.accessToken);
        const refreshToken = Cookie.get(COOKIE_NAMES.refreshToken);

        if (accessToken) {
          request.headers.set('Authorization', `Bearer ${accessToken}`);
        }

        if (request.url.includes('refresh')) {
          request.headers.set('Authorization', `Bearer ${refreshToken}`);
        }

        // Set default Content-Type header if it's not a multipart form request
        // since FormData sets the correct Content-Type header for us (complete w/ boundary attribute)
        const formContentTypeHeader = request.headers
          .get('Content-Type')
          ?.includes('multipart/form-data');

        if (!formContentTypeHeader) {
          request.headers.set('Content-Type', 'application/json');
        }
      },
    ],

    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          // Should only retry non-auth endpoints when we have a refresh token to start with
          const [, endpoint] = request.url.split(PREFIX_URL);
          const isAuthEndpoint = endpoint && endpoint.includes('auth/');
          const refreshToken = Cookie.get(COOKIE_NAMES.refreshToken);

          if (isAuthEndpoint || !refreshToken) {
            return;
          }

          // Try to get a fresh token
          try {
            const { refresh_token: newRefreshToken, access_token: newAccessToken } = await ky
              .post(PREFIX_URL + 'auth/refresh', {
                headers: {
                  Authorization: `Bearer ${refreshToken}`,
                },
              })
              .json();

            if (newAccessToken && newRefreshToken) {
              // Retry with new token
              setAuthCookies({ accessToken: newAccessToken, refreshToken: newRefreshToken });
              request.headers.set('Authorization', `Bearer ${newAccessToken}`);
              return ky(request, options);
            }
          } catch (err) {
            console.warn('Failed to fetch new token after 401:', err);
          }
        }
      },
    ],
  },
});

export const handleRawResponse = (res: Response) => {
  if (!res.ok) throw res;
  return res.json();
};

export const handleErrorResponse = (err: { response: Response }) => {
  return err.response.json().then((errors?: Error[]) => {
    const error = new Error(errors?.map((e: Error) => e.message).join('\n'));
    return Promise.reject(error);
  });
};
