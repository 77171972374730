//TODO change all references of role to organization

import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { adminAPI } from 'api';
import { RoleDetails, RoleUser } from '@types';
import { DialogCustom } from '../';
import { CACHE_KEYS } from 'utils/constants';

type Props = {
  close: () => void;
  open: boolean;
  role: RoleDetails;
  unassignedUsers: RoleUser[];
};

const useStyles = makeStyles(() =>
  createStyles({
    tableRow: {
      cursor: 'pointer',
    },
  }),
);

const AdminBulkUserDialog = ({ close, open, role, unassignedUsers }: Props) => {
  const classes = useStyles();
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);
  const queryClient = useQueryClient();

  const { mutate: bulkAssignUsersMutate } = useMutation(adminAPI.bulkAssignUsersToOrganization, {
    onSuccess: updatedUsers => {
      // Update cache after successful delete
      const allUsers = queryClient.getQueryData<RoleUser[]>(CACHE_KEYS.users) || [];
      const allUsersUpdated = allUsers.map(u => {
        const userToUpdate = updatedUsers.find(updatedUser => updatedUser.id === u.id);
        if (userToUpdate) {
          return {
            ...u,
            ...userToUpdate,
          };
        }
        return u;
      });

      queryClient.setQueryData(CACHE_KEYS.users, allUsersUpdated);
      // Refetch user-count because per-role counts will have changed
      queryClient.invalidateQueries(CACHE_KEYS.rolesDetailed);
      close();
    },
  });

  React.useEffect(() => {
    if (open) {
      setSelectedUserIds([]);
    }
  }, [open]);

  const handleSubmit = () => {
    bulkAssignUsersMutate({ organizationId: role.organizationId, userIds: selectedUserIds });
  };

  const handleCancel = () => {
    close();
  };

  const rowCount = unassignedUsers.length;
  const numSelected = selectedUserIds.length;

  return (
    <DialogCustom
      label="add-user-dialog"
      maxWidth="md"
      onClose={close}
      open={open}
      title={`Select and add users to ${role.name}`}
      actions={
        <>
          <Button color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!unassignedUsers.length}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Add User(s) & Send Invites
          </Button>
        </>
      }
    >
      {unassignedUsers.length ? (
        <Table aria-label="users table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={rowCount > 0 && numSelected === rowCount}
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  inputProps={{ 'aria-label': 'select all users' }}
                  onChange={() => {
                    if (numSelected === rowCount) {
                      setSelectedUserIds([]);
                    } else {
                      setSelectedUserIds(unassignedUsers.map(user => user.id));
                    }
                  }}
                />
              </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unassignedUsers.map(user => {
              const isSelected = selectedUserIds.includes(user.id);

              return (
                <TableRow
                  aria-checked={isSelected}
                  className={classes.tableRow}
                  hover
                  key={user.id}
                  role="checkbox"
                  tabIndex={-1}
                  onClick={e => {
                    if (isSelected) {
                      setSelectedUserIds(ids => ids.filter(id => id !== user.id));
                    } else {
                      setSelectedUserIds(ids => [...ids, user.id]);
                    }
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      color="primary"
                      inputProps={{ 'aria-labelledby': `select user ${user.id}` }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.firstName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.lastName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Typography align="center">Currently no un-assigned users.</Typography>
      )}
    </DialogCustom>
  );
};

export default AdminBulkUserDialog;
