import { useEffect } from 'react';
import Cookie from 'js-cookie';

import { authAPI } from 'api';
import { deleteAuthCookies, setAuthCookies } from 'utils/browser';
import { AUTH_TOKEN_EXPIRATION, COOKIE_NAMES, SESSION_POLL_INTERVAL } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { routes } from 'router/routes';
import jwt, { JwtPayload } from 'jsonwebtoken';

function isTokenExpired(token: string): boolean {
  const exp = ((jwt.decode(token) as JwtPayload)?.exp || 0) * 1000;
  return Date.now() > exp;
}

export function useSessionPoll() {
  const history = useHistory();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    setTimeout(() => {
      interval = setInterval(async () => {
        const token = Cookie.get(COOKIE_NAMES.accessToken);
        if (token && isTokenExpired(token)) {
          deleteAuthCookies();
          history.push(routes.login.value);
        }
      }, SESSION_POLL_INTERVAL);
    }, SESSION_POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [history]);
}

export function useTokenRefreshInterval() {
  // Fetch new refresh token every X minutes
  useEffect(() => {
    let interval: NodeJS.Timeout;

    // Only start fetching refresh after app runs for X minutes
    setTimeout(() => {
      interval = setInterval(async () => {
        const token = Cookie.get(COOKIE_NAMES.refreshToken);
        if (!token) {
          return;
        }

        const tokenSet = await authAPI.refreshTokenSet();
        setAuthCookies(tokenSet);
      }, AUTH_TOKEN_EXPIRATION);
    }, AUTH_TOKEN_EXPIRATION);

    // Teardown callback
    return () => clearInterval(interval);
  }, []);
}

const onMouseDown = () => {
  typeof document !== 'undefined' && document.body.classList.add('using-mouse');
};

const onKeyDown = (event: KeyboardEvent) => {
  // If tabbing over to the input/button, show outline
  if (event.keyCode === 9 && typeof document !== 'undefined') {
    document.body.classList.remove('using-mouse');
  }
};

export const useWatchKeyboard = (): void => {
  useEffect(() => {
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', onMouseDown);
    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', onKeyDown);

    return () => {
      document.body.removeEventListener('mousedown', onMouseDown);
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, []);
};
