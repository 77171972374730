import download from 'downloadjs';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ParsedGeography } from '@types';
import { DialogCustom } from '../..';
import { SharedProps } from '../types';

type Props = SharedProps & {
  parsedGeographies: ParsedGeography[] | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topWrapper: {
      padding: theme.spacing(1.5, 0),
      borderTop: `1px solid ${theme.palette.kinsaGrey200}`,
      borderBottom: `1px solid ${theme.palette.kinsaGrey200}`,
    },
    top: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    jsonWrapper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.kinsaGrey200}`,
      borderRadius: 5,
      maxHeight: 300,
      overflow: 'auto',
    },
    json: {
      fontWeight: 500,
    },
  }),
);

const ViewJsonDialog = ({ className, parsedGeographies, open, close, setViewMode }: Props) => {
  const classes = useStyles();
  const prettyPrintGeo = JSON.stringify(parsedGeographies, null, 4);

  const handleDownload = () => {
    if (parsedGeographies) {
      download(prettyPrintGeo, 'custom_geo.json', 'application/json');
    }
  };

  const handleDelete = () => {
    setViewMode('delete json');
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      transitionDuration={0}
      open={open}
      maxWidth="xs"
      onClose={close}
      title="Geo Boundaries"
      label="view json boundaries"
      actions={
        <Button type="submit" variant="contained" color="primary" onClick={close}>
          Done
        </Button>
      }
    >
      <div className={classes.topWrapper}>
        <div className={classes.top}>
          <Typography variant="body2">Custom JSON</Typography>
          <div>
            <Button variant="text" color="primary" onClick={handleDelete}>
              Remove
            </Button>
            <Button variant="text" color="primary" onClick={handleDownload}>
              Download
            </Button>
          </div>
        </div>
        <Typography gutterBottom variant="body2" color="textSecondary">
          Removing this file will reset the geo permissions to the organization's category's default
          set.
        </Typography>
      </div>
      {parsedGeographies && (
        <div className={classes.jsonWrapper}>
          <Typography
            className={classes.json}
            variant="body2"
            component="pre"
            color="textSecondary"
          >
            {prettyPrintGeo}
          </Typography>
        </div>
      )}
    </DialogCustom>
  );
};

export default ViewJsonDialog;
