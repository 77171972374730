import React from 'react';

import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  actions: React.ReactNode;
  children: React.ReactNode;
  label: string;
  title: string;
  titleAction?: string;
  alwaysShowScrollbar?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  titleActionClickHandler?: () => void;
} & DialogProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 380,
    },
    actions: {
      alignItems: 'stretch',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(4, 3, 3),
    },
    textButton: {
      ...theme.typography.body1,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(2),
    },
    title: {
      fontWeight: 700,
      minWidth: 216,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(3, 3, 2, 3),
    },
    alwaysShowScrollbar: {
      overflowY: 'scroll',
      marginRight: 2,
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: 'rgba(0,0,0,0.5)',
        '-webkit-box-shadow': '0px 0px 1px rgba(255,255,255,0.5)',
      },
    },
  }),
);

const DialogCustom = ({
  children,
  title,
  titleAction,
  titleActionClickHandler,
  label,
  actions,
  alwaysShowScrollbar,
  maxWidth,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.container }}
      aria-labelledby={label}
      scroll="paper"
      maxWidth={maxWidth}
      {...props}
    >
      <DialogTitle className={classes.titleContainer} disableTypography id={label}>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        {titleAction && (
          <ButtonBase className={classes.textButton} onClick={titleActionClickHandler}>
            {titleAction}
          </ButtonBase>
        )}
      </DialogTitle>
      <DialogContent className={alwaysShowScrollbar ? classes.alwaysShowScrollbar : ''}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>{actions}</DialogActions>
    </Dialog>
  );
};

export default DialogCustom;
