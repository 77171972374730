import { useEffect, useState } from 'react';
import { Formik, FormikHelpers, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { TextInput } from '../Formik';
import { FullscreenFormContainer, RouterLink, ErrorText } from '../';
import { routes } from 'router/routes';
import { setDocumentTitle } from 'utils/browser';
import { authAPI } from 'api';
import { useToastContext } from 'hooks/context';

type FormValues = {
  email: string;
};

// Form validation schema
const FormSchema = Yup.object().shape({
  email: Yup.string().email('Valid email required').required('Required'),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      marginTop: theme.spacing(2),
    },
    btn: {
      margin: theme.spacing(4, 0, 2),
    },
  }),
);

const ForgotPassword = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState<string | null>(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    setDocumentTitle(routes.forgotPassword.label);
  }, []);

  return (
    <FullscreenFormContainer>
      <div className={classes.root}>
        <Typography variant="h4">Reset Password</Typography>

        <Typography className={classes.text} variant="body1" color="textSecondary" gutterBottom>
          We'll send an email with instructions on how to reset your password to the email address
          associated with your account.
        </Typography>

        <Typography className={classes.text} variant="body1" color="textSecondary" gutterBottom>
          If you don't receive this email within an hour or two, check your junk or spam folder.
        </Typography>

        <Formik<FormValues>
          initialValues={{ email: '' }}
          validationSchema={FormSchema}
          onSubmit={async (
            { email }: FormValues,
            { setSubmitting, resetForm }: FormikHelpers<FormValues>,
          ) => {
            try {
              setSubmitting(true);
              await authAPI.forgotPassword(email);
              showToast({
                type: 'success',
                title: 'success!',
                message: 'Password reset email sent to your inbox. It should arrive shortly.',
              });
              setServerError(null);
              resetForm();
            } catch (err) {
              const errorResp = err.response ? await err.response.json() : null;
              const errorMsg = _.get(errorResp, 'errors[0].message', 'Error resetting password.');
              setServerError(errorMsg);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }: FormikProps<FormValues>) => (
            <Form noValidate className={classes.form}>
              <Field
                required
                name="email"
                type="email"
                label="Email"
                margin="normal"
                component={TextInput}
              />

              <Button
                className={classes.btn}
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Reset Password
              </Button>

              <Link
                variant="body2"
                align="center"
                color="primary"
                component={RouterLink}
                to={routes.login.value}
              >
                Remember password?
              </Link>

              <ErrorText text={serverError} />
            </Form>
          )}
        </Formik>
      </div>
    </FullscreenFormContainer>
  );
};

export default withRouter(ForgotPassword);
