import { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

import { VIEW_MODE } from './constants';

import { OrganizationDetails, Team } from '@types';
import { useCheckboxes } from 'hooks/forms';
import { usePatchRole } from 'hooks/roles';
import { usePatchOrganization } from 'hooks/organizations';
import { DialogCustom } from 'components';
import { SharedProps } from 'components/AdminTeamsDialog/types';
import { AdminNoTeamsExistDialog } from '.';

type Props = SharedProps & {
  matchedOrganization?: OrganizationDetails;
  teamsByVertical: Team[];
  setSelectedTeam: Dispatch<SetStateAction<Team | undefined>>;
  newlyCreatedTeams: Team[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabelContainer: {
      marginRight: 0,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
    },
    checkbox: {
      alignSelf: 'flex-start',
      padding: theme.spacing(1),
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 240,
    },
    subtext: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    teamName: {
      fontSize: '15px',
      fontWeight: 500,
      marginBottom: theme.spacing(0.5),
      paddingTop: '9px',
    },
    editActions: {
      alignSelf: 'flex-start',
      visibility: 'hidden',
    },
    icon: {
      minWidth: 'unset',
      marginLeft: theme.spacing(1.5),
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      '&:hover': {
        background: 'unset',
      },
    },
    visible: {
      visibility: 'visible',
    },
  }),
);

const SelectTeamsDialog = ({
  close,
  matchedOrganization,
  open,
  selectedRole,
  setViewMode,
  setSelectedTeam,
  teamsByVertical,
  newlyCreatedTeams,
}: Props) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState<string | null>(null);

  const handleClose = () => {
    setSelectedTeam(undefined);
    setHovered(null);
    close();
  };

  const checkboxOptions = useMemo(
    () =>
      teamsByVertical.map(team => ({
        description: team.description,
        id: team.teamId.toString(),
        label: team.name,
        team,
      })),
    [teamsByVertical],
  );

  const {
    isAllChecked,
    isIndeterminateChecked,
    selectedOptions,
    setSelectedOptions,
    handleChange,
    handleChangeAll,
  } = useCheckboxes(checkboxOptions);

  const patchRole = usePatchRole();
  const patchOrganization = usePatchOrganization();

  useEffect(() => {
    if (open) {
      const defaultSelected = matchedOrganization?.teamIds.map(teamId => teamId.toString()) || [];

      // auto select newly created teams
      const newTeamIds = newlyCreatedTeams.map(team => team.teamId.toString());

      // Reset when opening dialog
      setSelectedOptions([...defaultSelected, ...newTeamIds]);
    }
  }, [matchedOrganization?.teamIds, open, setSelectedOptions, newlyCreatedTeams]);

  const handleSubmit = async () => {
    try {
      if (selectedRole?.roleId && matchedOrganization?.organizationId) {
        await patchOrganization.mutateAsync({
          organizationId: matchedOrganization.organizationId,
          teamIds: selectedOptions.map(option => Number(option)),
        });
        close();
      }
    } catch (err) {
      console.warn('Error updating teams', err);
    }
  };

  if (!teamsByVertical || !teamsByVertical.length) {
    return (
      <AdminNoTeamsExistDialog
        close={close}
        open={open}
        title="Select teams"
        message="Please create a new team to assign it to the organization."
        setViewMode={setViewMode}
      />
    );
  }

  return (
    <>
      <DialogCustom
        label="select teams"
        onClose={handleClose}
        open={open}
        title={`Select Teams (${selectedOptions.length}/${checkboxOptions.length})`}
        titleAction={'Create new team'}
        titleActionClickHandler={() => setViewMode(VIEW_MODE.createTeam)}
        actions={
          <>
            <Button color="primary" disabled={patchRole.isLoading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={patchRole.isLoading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      >
        <FormControl component="fieldset" fullWidth>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={isAllChecked}
                  color="primary"
                  indeterminate={isIndeterminateChecked}
                  name="all"
                  onChange={handleChangeAll}
                />
              }
              label="All"
            />

            {checkboxOptions.map((option, i) => (
              <div
                className={classes.row}
                key={option.id}
                onMouseEnter={() => setHovered(`${option.label}-${i}`)}
                onMouseLeave={() => setHovered(null)}
              >
                <FormControlLabel
                  className={classes.formLabelContainer}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      checked={selectedOptions.includes(option.id)}
                      color="primary"
                      name={option.id}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <div className={classes.label}>
                      <Typography className={classes.teamName}>{option.label}</Typography>

                      {option.description && (
                        <Typography className={classes.subtext} variant="caption">
                          {option.description}
                        </Typography>
                      )}
                    </div>
                  }
                />
                <div
                  className={classNames(classes.editActions, {
                    [classes.visible]: hovered === `${option.label}-${i}`,
                  })}
                >
                  <IconButton
                    className={classes.icon}
                    onClick={() => {
                      setSelectedTeam(option.team);
                      setViewMode(VIEW_MODE.editTeam);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className={classes.icon}
                    onClick={() => {
                      setSelectedTeam(option.team);
                      setViewMode(VIEW_MODE.deleteTeam);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </FormGroup>
        </FormControl>
      </DialogCustom>
    </>
  );
};

export default SelectTeamsDialog;
