import { Button, Typography } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';

import { adminAPI } from 'api';
import { CACHE_KEYS } from 'utils/constants';
import { DialogCustom } from '../..';
import { SharedProps } from '../types';

const DeleteJsonDialog = ({ className, selectedRole, open, close, setViewMode }: SharedProps) => {
  const queryClient = useQueryClient();

  const { mutate: resetGeographies, isLoading: isResettingGeographies } = useMutation(
    adminAPI.resetGeographiesByRole,
    {
      onSuccess: newGeographies => {
        queryClient.setQueryData([CACHE_KEYS.geography, selectedRole.roleId], newGeographies);
        setViewMode('default');
      },
    },
  );

  const handleCancel = () => {
    setViewMode('view json');
  };

  const handleConfirm = () => {
    resetGeographies({ roleId: selectedRole.roleId });
  };

  return (
    <DialogCustom
      classes={{ paper: className }}
      transitionDuration={0}
      open={open}
      maxWidth="xs"
      onClose={close}
      title="Remove Custom JSON?"
      label="remove custom json"
      actions={
        <>
          <Button color="primary" onClick={handleCancel} disabled={isResettingGeographies}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={isResettingGeographies}
          >
            Remove
          </Button>
        </>
      }
    >
      <Typography>
        This will reset the geo permissions to the organization's category's default set.
      </Typography>
    </DialogCustom>
  );
};

export default DeleteJsonDialog;
